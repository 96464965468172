import React, { useState } from "react";
// import "./ToggleSwitch.css";
import { Box, Grid, Button, Input } from "@mui/material";

function Switch() {
  const [checked, setChecked] = useState<boolean>(false);
  const onToggle = () => {
    setChecked(!checked);
    console.log(checked, "checked");
  };
  return (
    <>
      <div className="btn-container">
        {/* <i className="fa fa-sun-o" aria-hidden="true"></i> */}
        <label className="switch btn-color-mode-switch">
          <input
            type="checkbox"
            name="color_mode"
            id="color_mode"
            value="1"
            onChange={onToggle}
          />
          <label
            htmlFor="color_mode"
            data-on="BUSD"
            data-off="BNB"
            className="btn-color-mode-switch-inner"
          ></label>
        </label>

        {/* <i className="fa fa-moon-o" aria-hidden="true"></i> */}
        {checked ? (
          <>
            <Input
              className="bnb-busd"
              disableUnderline
              type="text"
              placeholder="0.00BUSD"
            />
            <Button className="max-busd">Max</Button>
          </>
        ) : (
          <>
            {" "}
            <Input
              className="bnb-busd"
              disableUnderline
              type="text"
              placeholder="0.00BNB"
            />
          </>
        )}
      </div>
    </>
  );
}
export default Switch;
