import { Divider, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import Web3 from "web3";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { SocialActionCreators } from "../../../store/reducers/socials/action-creators";
function StackingLeadboard() {
  const [first, setfirst] = useState(true);
  const [color, _] = useState("#ffffff");
  const dispatch = useDispatch();
  const { usersStakes, freezeInfo } = useTypedSelector(
    (state) => state.socials
  );

  const { Moralis } = useMoralis();

  const updateUser = useCallback(async () => {
    const fetchPositions = async () => {
      const Freeze = Moralis.Object.extend("Freeze");
      const newData = new Freeze();
      const query = await new Moralis.Query(newData);
      const monsters = query.find().then((res) => {
        dispatch(SocialActionCreators.setUsersStakers(res));
      });
    };
    fetchPositions();
  }, []);
  useEffect(() => {
    setfirst(false);
    updateUser();
  }, [updateUser]);

  let holder: any = {};

  let getParser = usersStakes.map((item) => {
    return {
      account: item.attributes.account,
      power: Web3.utils.fromWei(item.attributes.power),
    };
  });

  getParser?.forEach(function (d) {
    if (holder.hasOwnProperty(d.account)) {
      holder[d.account] = holder[d["account"]] + Number(d.power);
    } else {
      holder[d["account"]] = Number(d.power);
    }
  });

  let obj2: any = [];

  for (let prop in holder) {
    obj2.push({ account: prop, power: holder[prop] });
  }

  return (
    <>
      <div className="pool-title">
        <Typography className="stake-title">Staking Leaderboard</Typography>
      </div>
      <div className="stacking-leaderboard-section">
        <ul className="leaderboard-lists">
          {first ? (
            <ClipLoader color={color} size={20} />
          ) : (
            <>
              <li className="titles-staking">
                <span></span>
                <span>Account</span>
                <span>Days Locked</span>
                <span className="xswt">xSWT</span>
              </li>
              <Divider className="stake-divider" />
              {obj2
                ?.sort(function (a, b) {
                  return b.power - a.power;
                })
                .slice(0, 10)
                .map((item: any, index: number) => {
                  return (
                    <li className="leaderboard-list-items" key={index}>
                      <span>{index == 0 ? 1 : index + 1}</span>
                      <div>
                        {item.account &&
                          `${item.account.slice(0, 6)}...${item.account.slice(
                            item.account.length - 4,
                            item.account.length
                          )}`}
                      </div>
                      {freezeInfo ? (
                        <span className="days-stakes">
                          {freezeInfo[index]?.period / 28700}
                          &nbsp; days
                        </span>
                      ) : (
                        <span className="days-stakes">0&nbsp; days</span>
                      )}
                      <span className="xswt-coin">{parseInt(item?.power)}</span>
                    </li>
                  );
                })}
            </>
          )}
        </ul>
      </div>
    </>
  );
}

export default React.memo(StackingLeadboard);
