import React, { useEffect, useState } from "react";
import WebFont from "webfontloader";
import ApiswapModal from "./components/Header/Apiswap/ApiswapModal";
import Layout from "./layout/Layout";
import Home from "./pages/home";

import { Route, Routes } from "react-router-dom";
import Apiswap from "./components/Header/Apiswap";

const  App:React.FC  = () => {
  const [firstLoaded, setFirstLoaded] = useState(true);
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Arciform sans", "Montserrat"],
      },
    });
  }, []);
  setTimeout(() => {
    setFirstLoaded(false);
  }, 1000);

  return (
    <div className={`font-loader`}>
      {firstLoaded ? (
        <></>
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Layout>
                  <Home />
                  <ApiswapModal />
                </Layout>
              </>
            }
          />
          <Route path="/apeswap" element={<Apiswap />} />
          <Route path="/total-supply" element={<span>100.000.000</span>} />
          <Route path="/circulating-supply" element={<span>4.000.000</span>} />
        </Routes>
      )}
    </div>
  );
}

export default App;
