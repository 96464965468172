import Done4 from "../../../svg/check-4.png";
import Done from "../../../svg/DoneTest.png";
import Done1 from "../../../svg/done_colored1.png";
import Done2 from "../../../svg/done_colored2.png";

import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Roadmap() {
  return (
    <div className="roadMap_container" id="roadMap">
      <div id="main-slide" className="roadmap-slide" data-ride="carousel">
        <Box className="testBox">
          <Box className={"quarter-divider22"}>
            <Box className={"divider-line"}>
              <Box className={"divider-circle1"}></Box>
            </Box>
            <Box className={"divider-line"}>
              <Box className={"divider-circle2"}></Box>
            </Box>
            <Box className={"divider-line"}>
              <Box className={"divider-circle3"}></Box>
            </Box>
            <Box className={"divider-line"}>
              <Box className={"divider-circle4"}></Box>
            </Box>
          </Box>
        </Box>
        <Box className="roadmap-inner">
          <Box className={"roadmap-tablet-rev roadmap-tablet roadmap-mobile"}>
            <Box className="quarters-item" data-aos="flip-left">
              <div className="quarters-item-child">
                <div className="title-quarter title-quarter1">
                  <h3>Quarter 1</h3>
                  <div className="circle-tablet circle-tablet1"></div>
                </div>
                <ul>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done} alt={"dksfklsdfkl"} />
                    </label>
                    Seed Phase
                  </li>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done} alt={"dksfklsdfkl"} />
                    </label>
                    50,000 Community
                  </li>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done} alt={"dksfklsdfkl"} />
                    </label>
                    Whitepaper
                  </li>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done} alt={"dksfklsdfkl"} />
                    </label>
                    CoinMarketCap <br /> ICO listing
                  </li>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done} alt={"dksfklsdfkl"} />
                    </label>
                    NFT Airdrop
                  </li>

                  {/* <li> Community</li> */}
                </ul>
              </div>
            </Box>
            <Box className="quarters-item" data-aos="flip-left">
              <div className="quarters-item-child">
                <div className="title-quarter title-quarter2">
                  <h3>Quarter 2</h3>
                  <div className="circle-tablet circle-tablet2"></div>
                </div>
                <ul>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done1} alt={"dksfklsdfkl"} />
                    </label>
                    SWT Airdrop{" "}
                  </li>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done1} alt={"dksfklsdfkl"} />
                    </label>
                    Platform Launch{" "}
                  </li>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done1} alt={"dksfklsdfkl"} />
                    </label>
                    Staking Launch{" "}
                  </li>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done1} alt={"dksfklsdfkl"} />
                    </label>
                    <span>Partnerships</span>
                  </li>
                  <li>
                    {" "}
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done1} alt={"dksfklsdfkl"} />
                    </label>{" "}
                    Influencer <br /> Marketing Push
                  </li>
                </ul>
              </div>
            </Box>
          </Box>
          <Box className={"quarter-divider-tablet"}>
            <Box className={"divider-line1"}>
              <Box className={"divider-circle"}></Box>
            </Box>
            <Box className={"divider-line2"}>
              <Box className={"divider-circle"}></Box>
            </Box>
          </Box>
          <Box className={"roadmap-tablet roadmap-mobile"}>
            <Box className="quarters-item" data-aos="flip-left">
              <div className="quarters-item-child">
                <div className="title-quarter title-quarter3">
                  <h3>Quarter 3</h3>
                  <div className="circle-tablet circle-tablet3"></div>
                </div>
                <ul>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done2} alt={"dksfklsdfkl"} />
                    </label>
                    <span> 5,000 Holders</span>
                  </li>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done2} alt={"dksfklsdfkl"} />
                    </label>

                    <span>Public Sale</span>
                  </li>

                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done2} alt={"dksfklsdfkl"} />
                    </label>
                    <span>
                      Listing on Different <br />
                      Exchanges
                    </span>
                  </li>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done2} alt={"dksfklsdfkl"} />
                    </label>
                    <span>Exchanger Launch</span>
                  </li>
                  <li>
                    <label className="done-label">
                      {/*<Done />*/}
                      <LazyLoadImage src={Done2} alt={"dksfklsdfkl"} />
                    </label>
                    <span>100,000 Community</span>
                  </li>
                </ul>
              </div>
            </Box>
            <Box className="quarters-item" data-aos="flip-left">
              <div className="quarters-item-child">
                <div className="title-quarter title-quarter4">
                  <h3>Quarter 4</h3>
                  <div className="circle-tablet circle-tablet4"></div>
                </div>
                <ul>
                  <li>
                    <label className="done-label">
                      <LazyLoadImage src={Done4} alt={"dksfklsdfkl"} />
                    </label>
                    <span>CoinGecko Listing</span>
                  </li>
                  <li>
                    <p>&nbsp;&nbsp;NFT Release</p>
                  </li>
                  <li>
                    <p>&nbsp;&nbsp;Pools Opening</p>
                  </li>
                  <li>
                    <p>&nbsp;&nbsp;NFT Game</p>
                  </li>

                  <li>
                    <p>&nbsp;&nbsp;NFT Marketplace</p>
                  </li>
                  <li>
                    <p>&nbsp;&nbsp;SweetPad Game</p>
                  </li>
                  <li>
                    <p>&nbsp;&nbsp;SweetPad Metaverse</p>
                  </li>
                  <li>
                    <p>&nbsp;&nbsp;150,000 Community</p>
                  </li>
                  <li>
                    <p>&nbsp;&nbsp;10,000 Holders</p>
                  </li>
                </ul>
              </div>
            </Box>
          </Box>
        </Box>

        <Box className={"quarter-divider"}>
          <Box className={"divider-line"}>
            <Box className={"divider-circle1"}></Box>
          </Box>
          <Box className={"divider-line"}>
            <Box className={"divider-circle2"}></Box>
          </Box>
          <Box className={"divider-line"}>
            <Box className={"divider-circle3"}></Box>
          </Box>
          <Box className={"divider-line"}>
            <Box className={"divider-circle4"}></Box>
          </Box>
        </Box>
        <br />
        <Box id="quarter-divider"></Box>
      </div>
    </div>
  );
}
