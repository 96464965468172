import React, { Component, useEffect, useState } from "react";
import Carousel from "react-spring-3d-carousel";
import uuidv4 from "uuid";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { config } from "react-spring";
import one from "../../../svg/Miro.svg";
import two from "../../../svg/Ario.svg";
import tree from "../../../svg/Neo.svg";
import { useSwipeable } from "react-swipeable";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

export default function MySlide() {
  let sliderContent = document.querySelector(".content-slider");
  let sliderInner = document.querySelector(".css-doq0dk");
  let slideImage = document.querySelectorAll(".css-1fzpoyk");

  let pressed = false;
  let startX;
  let x;

  const changeHandler = (e) => {
    pressed = true;
    startX = e.clientX - sliderInner.offsetLeft;
    console.log(startX, "jh");
    sliderContent.style.cursor = "grab";
  };
  const mouseLeave = (e) => {
    sliderContent.style.cursor = "default";
  };
  window.addEventListener("mouseup", () => {
    pressed = false;
  });
  const { result } = useTypedSelector((state) => state.socials);
  const [left, setleft] = useState(0);
  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: false,
    config: config.gentle,
  });
  const handleSwipe = () => {
    setleft(-350);
  };
  let slides = [
    {
      key: 1,
      //   content: <img src={one} alt="1" />
      content: (
        <div
          draggable="false"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            // alt="img_1"
            className="dragon-name name-title dragon-ario"
            style={{
              position: "relative",
              // left: "20px",
              // top: "25px",
            }}
          >
            <span>Neo</span>
            <p>Tier 6</p>
          </div>
          <img
            draggable="false"
            src={tree}
            alt="own_image"
            style={{
              // width: "250px",

              height: "300px",
              position: "relative",
              // left: "20px",
            }}
          />
          <div className="price-dragon price-ario">
            <pre className="dragon-name">
              <span>100.000</span>
              <span>xSWT</span>
              <br />
              <p>Guaranteed Allocation</p>
            </pre>
          </div>
        </div>
      ),
    },
    {
      key: 2,
      //   content: <img src={one} alt="1" />
      content: (
        <div
          draggable="false"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            // alt="img_1"
            className="dragon-name name-title dragon-ario"
            style={{
              position: "relative",
            }}
          >
            <span>Ario</span>
            <p>Tier 4</p>
          </div>
          <img
            draggable="false"
            src={two}
            alt="own_image"
            style={{
              width: "250px",

              position: "relative",
              left: "20px",
            }}
          />
          <div className="price-dragon price-ario">
            <pre className="dragon-name">
              <span>10.000</span>
              <span>xSWT</span>
              <br />
              <p>Guaranteed Allocation</p>
            </pre>
          </div>
        </div>
      ),
    },
    {
      key: 3,
      //   content: <img src={one} alt="1" />

      content: (
        <div
          draggable="false"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="dragon-name name-title dragon-ario"
            style={{
              position: "relative",
            }}
          >
            <span>Miro</span>
            <p>Tier 5</p>
          </div>
          <img
            draggable="false"
            src={one}
            alt="own_image"
            style={{
              width: "250px",

              position: "relative",
              // left: "20px",
            }}
          />
          <div className="price-dragon price-ario">
            <pre className="dragon-name">
              <span>50.000</span>
              <span>xSWT</span>
              <br />
              <p>Guaranteed Allocation</p>
            </pre>
          </div>
        </div>
      ),
    },
  ].map((slide, index) => {
    return {
      ...slide,
      onClick: () => setState({ ...state, goToSlide: index }),
    };
  });
  useEffect(() => {
    if (result >= 10000 && result <= 49999) {
      setState({ goToSlide: slides[0].key });
    } else if (result >= 50000 && result <= 99999) {
      setState({ goToSlide: slides[1].key });
    } else if (result >= 100000) {
      setState({ goToSlide: slides[2].key });
    } else {
      return setState({ goToSlide: slides[0].key });
    }
  }, [result]);

  return (
    <div
      onMouseDown={changeHandler}
      onMouseLeave={mouseLeave}
      className="content-slider"
      style={{
        height: "400px",
        width: " 100%",
        margin: " 0 auto",
        position: "relative",
        left: left,
      }}
    >
      {/* <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      > */}
      <Carousel
        slides={slides}
        goToSlide={state.goToSlide}
        offsetRadius={state.offsetRadius}
        showNavigation={state.showNavigation}
        animationConfig={state.config}
      />
      {/* </Swiper> */}
      <div
        style={{
          margin: "0 auto",
          marginTop: "2rem",
          width: "50%",
          display: "flex",
          justifyContent: "space-around",
        }}
      ></div>
    </div>
  );
}
