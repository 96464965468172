// import {
//   Input,
//   InputGroup,
//   InputLeftElement,
//   InputRightElement,
// } from "@chakra-ui/react";
import React from "react";
import { addDays } from "../../../../helper/date";
import {
  Button,
  Input,
  Box,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";

import { ReactComponent as MinusPassive } from "../../../../icons/minus-passive.svg";
import { ReactComponent as Minus } from "../../../../icons/minus.svg";
import { ReactComponent as PlusPassive } from "../../../../icons/plus passive.svg";
import { ReactComponent as Plus } from "../../../../icons/plus.svg";
interface Iprops {
  isDisabledMinus: boolean;
  handleDeCrease: () => void;
  isDisabledPlus: boolean;
  days: number;
  handleInCrease: () => void;
  handleChangeDays: (e: React.ChangeEvent<HTMLInputElement>) => void;
  warningText: string;
  result: number;
  tab?: string;
}

export default function DaysCalc({
  isDisabledMinus,
  handleDeCrease,
  isDisabledPlus,
  days,
  handleInCrease,
  handleChangeDays,
  // handleBlurDays,
  warningText,
  result,
}: Iprops) {
  return (
    <>
      <Box className="calc-top-section">
        <Box className={"calculator-sweetcoin calculator-sweetcoin-tablet"}>
          <Typography className="calculator-sweetcoin-text">
            Days you lock tokens for
          </Typography>
          <TextField
            // type="number"
            // className="year-input"
            fullWidth
            type="tel"
            value={days === 0 ? "" : days}
            onChange={handleChangeDays}
            placeholder="365"
            className="input-group-days"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {isDisabledMinus ? (
                    <MinusPassive className="minus-passive" />
                  ) : (
                    <Minus className="minus-min" onClick={handleDeCrease} />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isDisabledPlus ? (
                    <PlusPassive className="passive-plus" />
                  ) : (
                    <Plus className="plus-min" onClick={handleInCrease} />
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Box>
        <Typography className="warning-text war-text">{warningText}</Typography>
      </Box>
      <Box className="bottom-calc-section">
        <Input
          className="calculator-sweetcoin-footer-input swt-input"
          style={{ cursor: "pointer" }}
          type="text"
          placeholder="0.00 sweet"
          onChange={() => {}}
          readOnly={true}
          value={`${result.toFixed(2)} xSWT`}
          fullWidth
          disableUnderline
        />
        <div className="until-and-error-text">
          {Number(result.toFixed(2)) < 10000 &&
          Number(result.toFixed(2)) > 0 ? (
            <p className="warning-text-error">
              At least 10.000 xSWT is required
            </p>
          ) : (
            <p></p>
          )}
        </div>
        <p className="calculator-left-align-footer">
          {`Locked until ${addDays(days)}`}
        </p>
      </Box>
    </>
  );
}
