import { Box, ClickAwayListener, Grid, MenuList } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

interface Iprops {
  scrollSmoothTo: (item: any) => void;
  menu: boolean;
  setMenu: (menu: boolean) => void;
  location?: any;
}

const InfoContents = ({ scrollSmoothTo, menu, location, setMenu }: Iprops) => {
  const { openBurgerMenu } = useTypedSelector((state) => state.socials);

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };

  const { height, width } = useWindowDimensions();

  let infoItemDesctop = [
    { id: 3, link: "calculator-id", title: "Calculator" },
    { id: 1, link: "tokeno-conetnt", title: "Tokenomics" },
    {
      id: 2,
      link: width < 500 ? "roadmap-scroll-mobile" : "roadmap-scroll",
      title: "Roadmap",
    },
    {
      id: 4,
      link: width < 500 ? "quarter-divider-mobile" : "quarter-divider",
      title: "Media",
    },
  ];

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  let items = infoItemDesctop.map((elem) => {
    return (
      <Box className="info-item-border header-item-border " key={elem.id}>
        <Box className="info-item read-more-button">
          <h2
            className="info-item-h2"
            onClick={() => scrollSmoothTo(elem.link)}
          >
            {elem.title}
          </h2>
        </Box>
      </Box>
    );
  });

  return (
    <Box
      className={`nav-container
    
        ${menu ? "show-nav-panel" : ""}
    `}
    >
      <Box
        className={`info-content ${
          location?.pathname == "/apeswap" ? "swap" : ""
        }`}
      >
        {items}
        <NavLink
          style={{
            textDecoration: "none",
            color: "white",
            fontWeight: 400,
            fontSize: "20px",
            fontFamily: "Arciform Sans",
          }}
          className={"apiswap-link"}
          to="apeswap"
        >
          Swap
        </NavLink>
      </Box>
    </Box>
  );
};

export default InfoContents;
