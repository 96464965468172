// import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SocialActionCreators } from "../../../store/reducers/socials/action-creators";
import BuySweetButton from "../../walletConnect/BuySweetButton";
import Liquidity from "./Liquidity/Liquidity";
import LPstacking from "./LP Stacking/lp-stacking";
import NFTstacking from "./NFT/nft-stacking";
import DaysCalc from "./SWT/days-calc";
import SWTcalc from "./SWT/swt-calc";
import SwtStacking from "./SwtStacking";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

import { useTypedSelector } from "../../../hooks/useTypedSelector";
import BuySWT from "../../Header/about/BuySWT";
interface Props {
  days: number;
  setDays: (value: any) => void;
  setCard: (val: any) => void;
  changeCardHandler: (card: any, num: number) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Calculator = ({ days, setDays, setCard, changeCardHandler }: Props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { connected } = useTypedSelector((state) => state.socials);
  let address = localStorage.getItem("walletconnectedaddress");
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };
  const [sweetCoin, setSweetCoin] = useState<string>("100000");
  const [disableTab, setDisableTab] = useState<boolean>(true);
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [result, setResult] = useState<number>(0);
  const [warningText, setWarningText] = useState<string>("");
  const [isDisabledPlus, setIsDisabledPlus] = useState<boolean>(false);
  const [isDisabledMinus, setIsDisabledMinus] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleChangeCoin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSweetCoin(e.target.value);
    if (Number(sweetCoin) !== 0) {
      setDisableTab(true);
    } else if (Number(sweetCoin) === 0) {
      setDisableTab(false);
    }
    setDisableInput(false);
  };
  const handleFocus = () => {
    setDisableTab(true);
    setDisableInput(false);
  };
  const handleChangeDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value);
    setDays(value);
  };

  useEffect(() => {
    if (days === 182) {
      setResult(0.5 * Number(sweetCoin));
    }
    if (days >= 183 && days <= 365) {
      setResult((1 / 365) * days * Number(sweetCoin));
    } else if (days >= 366 && days <= 1095) {
      setResult(((2 / 730) * ((days - 365) / 2) + 1) * Number(sweetCoin));
    }
  }, [sweetCoin, days]);

  useEffect(() => {
    if (days <= 182) {
      setIsDisabledMinus(true);
    } else if (days >= 1095) {
      setIsDisabledPlus(true);
      setIsDisabledMinus(false);
    } else {
      setIsDisabledPlus(false);
      setIsDisabledMinus(false);
    }
    if (days < 182) {
      setWarningText("Required minimum day are 182 ");
    } else if (days > 1095) {
      setIsDisabledPlus(true);
      setIsDisabledMinus(false);

      setWarningText("Required maximum day are 1095");
    } else {
      setWarningText("");
    }
  }, [days]);

  const handleInCrease = () => {
    setDays((prev: any) => {
      if (prev >= 1095) {
        setIsDisabledPlus(true);
        return 1095;
      } else {
        return prev + 1;
      }
    });
  };
  const handleDeCrease = () => {
    setDays((prev: any) => {
      if (prev <= 182) {
        setIsDisabledMinus(true);
        return 182;
      } else {
        return prev - 1;
      }
    });
  };
  const handleBlurDays = (e: any) => {
    let value = Number(e.target.value);
    if (e.target.value < 5) {
      value = 182;
    } else if (e.target.value > 30) {
      value = 1095;
    }
    setDays(value);
  };

  useEffect(() => {
    result > 0
      ? dispatch(SocialActionCreators.setResultSlider(result))
      : dispatch(SocialActionCreators.setResultSlider(0));
  }, [result]);

  return (
    <React.Fragment>
      <Box className="calcualtor-content">
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="header-tabs"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              style={{ minWidth: 40 }}
              label="SWT Calculator"
              {...a11yProps(0)}
            />
            <Tab
              style={{ minWidth: 40 }}
              label="LP Staking"
              {...a11yProps(1)}
              // disabled
            />
            <Tab
              style={{ minWidth: 40 }}
              label="ADD Liquidity"
              {...a11yProps(2)}
              disabled
            />
            <Tab
              style={{ minWidth: 40 }}
              label="NFT Staking"
              {...a11yProps(3)}
              disabled
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box>
            <Typography className="swt-calc-title">
              Calculate your SWT depending on the amount of staked tokens and
              your lock time.
            </Typography>
          </Box>
          <SWTcalc
            disableInput={disableInput}
            sweetCoin={sweetCoin}
            handleFocus={handleFocus}
            handleChangeCoin={handleChangeCoin}
            setSweetCoin={setSweetCoin}
          />
          <DaysCalc
            isDisabledMinus={isDisabledMinus}
            handleDeCrease={handleDeCrease}
            isDisabledPlus={isDisabledPlus}
            handleInCrease={handleInCrease}
            days={days}
            handleChangeDays={handleChangeDays}
            warningText={warningText}
            result={result}
          />
          <Box className="buttons-group">
            <div className="swt-nft-buttons">
              <BuySWT />
            </div>
            <div className="click-staked-approve">
              {connected || address ? (
                <SwtStacking sweetCoin={sweetCoin} days={days} />
              ) : (
                <BuySweetButton btnName={"Connect Wallet"} />
              )}
            </div>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LPstacking
            isDisabledMinus={isDisabledMinus}
            handleDeCrease={handleDeCrease}
            isDisabledPlus={isDisabledPlus}
            handleInCrease={handleInCrease}
            days={days}
            sweetCoin={sweetCoin}
            setSweetCoin={setSweetCoin}
            handleChangeDays={handleChangeDays}
            handleBlurDays={handleBlurDays}
            warningText={warningText}
            result={result}
            setResult={setResult}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Liquidity
            isDisabledMinus={isDisabledMinus}
            handleDeCrease={handleDeCrease}
            isDisabledPlus={isDisabledPlus}
            handleInCrease={handleInCrease}
            days={days}
            handleChangeDays={handleChangeDays}
            handleBlurDays={handleBlurDays}
            warningText={warningText}
            result={result}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <NFTstacking
            tabIndex={tabIndex}
            isDisabledMinus={isDisabledMinus}
            handleDeCrease={handleDeCrease}
            isDisabledPlus={isDisabledPlus}
            handleInCrease={handleInCrease}
            days={days}
            handleChangeDays={handleChangeDays}
            handleBlurDays={handleBlurDays}
            warningText={warningText}
            result={result}
          />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};
export default Calculator;
