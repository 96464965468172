import React, { useEffect, useRef, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { sweetAddress, sweetFreezing } from '../../../utils/contractaddresses'
import { AbiItem } from 'web3-utils'
import SweetTokken from '../../../utils/ABI/sweet-token.json'
import SweetFreezingTest_abi from '../../../utils/ABI/sweet-freezing.json'
import { ethers } from 'ethers'

import { useDispatch } from 'react-redux'
import { SocialActionCreators } from '../../../store/reducers/socials/action-creators'
import { Button } from '@mui/material'

declare global {
  // tslint:disable-next-line
  interface Window {
    web3: any
    ethereum: any
    Web3Modal: any
    [name: string]: any
  }
}

export default function SwtStacking({ sweetCoin, days }) {
  let address = localStorage.getItem('walletconnectedaddress')
  const dispatch = useDispatch()
  const { provider, freezesBlock, connected } = useTypedSelector(
    (state) => state.socials,
  )

  const loading = useRef(false)
  const [color, _] = useState('#ffffff')
  const [first, setfirst] = useState(false)

  const [maxAppprove, setMaxAppproved] = useState(0)
  const stakeApprove = async () => {
    try {
      loading.current = true
      setfirst(true)
      let maxApprove: any = ethers.constants.MaxUint256
      let sweetContract = await new provider.eth.Contract(
        SweetTokken as AbiItem[],
        sweetAddress,
      )
      let accounts = await provider.eth.getAccounts()
      await sweetContract.methods.approve(sweetFreezing, maxApprove).send({
        from: accounts[0],
        gasLimit: 100000,
      })

      let a = provider.utils.toWei(
        await sweetContract.methods
          .allowance(accounts[0], sweetFreezing)
          .call(),
      )

      setMaxAppproved(a)
    } catch {
      loading.current = false
      setfirst(false)
    }
  }
  const freezeSwt = async () => {
    try {
      setfirst(true)
      loading.current = true
      let freezingSweet = await new provider.eth.Contract(
        SweetFreezingTest_abi as AbiItem[],
        sweetFreezing,
      )

      let accounts = await provider.eth.getAccounts()

      let blocks = await freezingSweet.methods.getBlocksPerDay().call()

      await freezingSweet.methods
        .freezeSWT(provider.utils.toWei(sweetCoin), blocks * days)
        .send({
          from: accounts[0],
        })

      let getFreezes = await freezingSweet.methods
        .getFreezes(accounts[0])
        .call()
      dispatch(SocialActionCreators.setFreezesBlock(getFreezes))
    } catch {
      loading.current = false
      setfirst(false)
    }
  }

  useEffect(() => {
    async function contractData() {
      //     let freezingSweet = await new provider.eth.Contract(
      //       SweetFreezingTest_abi as AbiItem[],
      //       sweetFreezing
      //     );
      //     let getFreezes = await freezingSweet.methods
      //     .getFreezes('0x47e609e7d77b5fb4cbda4c79671b96b2af34b59a')
      //     .call();
      // console.log(getFreezes, 'getFreezesgetFreezes');
      try {
        let sweetContract = await new provider.eth.Contract(
          SweetTokken as AbiItem[],
          sweetAddress,
        )
        let accounts = await provider.eth.getAccounts()
        let maxApproveed = provider.utils.toWei(
          await sweetContract.methods
            .allowance(accounts[0], sweetFreezing)
            .call(),
        )
        setMaxAppproved(maxApproveed)
      } catch {}
    }
    contractData()
  }, [])
  useEffect(() => {
    loading.current = false
    if (!loading.current) {
      setfirst(false)
    }
  }, [freezesBlock])
  useEffect(() => {
    loading.current = false
    if (!loading.current) {
      setfirst(false)
    }
  }, [maxAppprove])
  useEffect(() => {
    window.addEventListener('beforeunload', () =>
      localStorage.removeItem('walletconnectedaddress'),
    )
  }, [])

  return (
    <>
      {Number(maxAppprove) > 0 && address ? (
        <Button onClick={() => freezeSwt()}>
          Stake
          {loading.current || first ? (
            <ClipLoader color={color} size={20} />
          ) : null}
        </Button>
      ) : (
        <Button onClick={() => stakeApprove()}>
          Approve
          {loading.current || first ? (
            <ClipLoader color={color} size={20} />
          ) : null}
        </Button>
      )}
    </>
  )
}
