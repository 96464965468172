import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Logo from "../../../svg/logo-title.png";
import PinkSale from "../../../svg/pink-saleHover.png";
import X from "../../../svg/swt-pink-cl.png";
import SweetCoin from "../../../svg/sweet-coin-icon.png";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import PinkSaleModal from "./Pinksale";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  p: 5,
};

export default function ApiswapModal() {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="card-pinksale">
        <IconButton className="card-close-btn" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
        >
          <SwiperSlide>
            <PinkSaleModal handleClose={handleClose} />
          </SwiperSlide>
          {/* <SwiperSlide>
            <Box className="image-card-header">
              <img src={Logo} />

              <img src={X} />

              <img src={PinkSale} />
            </Box>
            <Box className="text-card-section">
              <img src={SweetCoin} />
              <Box className="text-desc">
                <Typography className="participate-sweet">
                  Participate in SweetPad’s FairLaunch on PinkSale
                </Typography>
                <Typography className="time">27.09.2022 17:00 UTC</Typography>
              </Box>
            </Box>
            <Box className="buy-href">
              <a
                className="buy-sweet-btn-href"
                target={"_newblank"}
                href="https://apeswap.finance/swap"
              >
                <Button className="buy-sweet-now">Buy Now</Button>
              </a>
            </Box>
          </SwiperSlide> */}
        </Swiper>
      </Box>
    </Modal>
  );
}
