import React from "react";
import Icoholder from "../../../svg/IcoHolder.png";
import Icoholder_1 from "../../../svg/IcoHolder_1.png";

import CoinMarketCap from "../../../svg/coinmarketcap_logo.png";
import CoinMarketCap_1 from "../../../svg/coinmarketcap_logo_1.png";

import Export_1 from "../../../svg/Cryptoinvest.png";
import Export from "../../../svg/Export.png";

import FreshCoin from "../../../svg/freshcoin.png";
import FreshCoin_1 from "../../../svg/freshcoin_1.png";

import SoLaunch from "../../../svg/solaunch.png";
import SoLaunch_1 from "../../../svg/solaunch_1.png";

import Bnbcoin from "../../../svg/Bnbcoin.png";
import Bnbcoin_1 from "../../../svg/Bnbcoin_1.png";

import Coinscope from "../../../svg/Coinscope.png";
import Coinscope_1 from "../../../svg/Coinscope_1.png";

import Nipper from "../../../svg/nipper_1.png";
import Nipper_1 from "../../../svg/nipper.png";

import CoinHub from "../../../svg/CoinHub.png";
import CoinHub_1 from "../../../svg/CoinHub_1.png";

import Crypto from "../../../svg/cryptotalk.png";
import Crypto_1 from "../../../svg/crypto-talk_1.png";

import DexTools from "../../../svg/dex_tools.png";
import DexTools_1 from "../../../svg/dex_tools_1.png";

import DapRadar from "../../../svg/dapradar.png";
import DapRadar_1 from "../../../svg/dapradar_1.png";

import BscNEWS from "../../../svg/Bsc-new.png";
import BscNEWS_1 from "../../../svg/Bsc-new_1.png";

import CryptoDaily from "../../../svg/crypto-daily.png";
import CryptoDaily_1 from "../../../svg/crypto-dayly.png";

import Pinksale from "../../../svg/pink-sale.png";
import Pinksale_1 from "../../../svg/pink-saleHover.png";

import Coinpaprika from "../../../svg/coinpaprika.png";
import Coinpaprika_1 from "../../../svg/coinpaprika_1.png";

import Coinhunt from "../../../svg/coinhunt.png";
import CoinHunt_1 from "../../../svg/coinhunt_1.png";
import CoinGeko from "../../../svg/coingeko.png";
import CoinGeko_1 from "../../../svg/coingeko1.png";
import Livewatch from "../../../svg/livewatch.png";
import Livewatch_1 from "../../../svg/livewatch_1.png";
import Coinmooner from "../../../svg/coinmooner.png";
import Coinmooner_1 from "../../../svg/coinmooner_1.png";
import ApeSwapLogo from "../../../svg/apeswap.png";
import ApeSwapHover from "../../../svg/apswapHover.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, Typography } from "@mui/material";

export default function Partners() {
  return (
    <Box className="partners-component" id="social">
      <Box className="pool-title">
        <Typography className="title-partners">Partners & Media</Typography>
      </Box>

      <Box className="partners-container">
        <>
          <a href="https://apeswap.finance" target={"_blank"}>
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage
                  effect="blur"
                  style={{ width: "100%" }}
                  src={ApeSwapLogo}
                  alt="apeswap"
                />
                <LazyLoadImage
                  src={ApeSwapHover}
                  style={{ width: "100%" }}
                  alt="ApeSwapHover"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://coinmarketcap.com/currencies/sweetpad/"
            target="_blank"
            aria-label="CoinMarketCap"
          >
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage
                  effect="blur"
                  src={CoinMarketCap}
                  alt="CoinMarketCap"
                />
                <LazyLoadImage
                  src={CoinMarketCap_1}
                  alt="CoinMarketCap"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://www.coingecko.com/en/coins/sweettoken"
            target="_blank"
            aria-label="BscNEWS"
          >
            <section className="partners-section top-box">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={CoinGeko} alt="coingeko" />
                <LazyLoadImage
                  src={CoinGeko_1}
                  alt="coingeko"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://www.bsc.news/post/sweetpad-fundraising-and-trading-platform-on-binance-smart-chain"
            target="_blank"
            aria-label="BscNEWS"
          >
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={BscNEWS} alt="BscNEWS" />
                <LazyLoadImage
                  src={BscNEWS_1}
                  alt="BscNEWS"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://coinhunt.cc/coin/634d6321afe03e1be08a2a98"
            aria-label="Crypto"
            target="_blank"
          >
            <section className="partners-section cryptotalk">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={CoinHunt_1} alt="CoinHunt" />
                <LazyLoadImage
                  src={Coinhunt}
                  alt="CoinHunt"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://coinmooner.com/coin/sweettoken-swt"
            aria-label="Coinmooner"
            target="_blank"
          >
            <section className="partners-section cryptotalk">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={Coinmooner_1} alt="Crypto" />
                <LazyLoadImage
                  src={Coinmooner}
                  alt="Coinmooner"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://solaunch.io/#/"
            target="_blank"
            aria-label="SoLaunch"
          >
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={SoLaunch} alt="SoLaunch" />

                <LazyLoadImage
                  src={SoLaunch_1}
                  alt="SoLaunch"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            // href="https://cryptodaily.io/sweetpad-your-sweetest-launchpad-is-launching-private-sale/"
            href="https://www.dextools.io/app/en/bnb/pair-explorer/0xd2280ae010ce3e519a09a459e200bacd303ea330"
            target="_blank"
            aria-label="ICO"
          >
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage
                  // effect="blur"
                  src={DexTools_1}
                  alt="DexTools"
                  className={"ico_mobile"}
                  style={{ width: "100px" }}
                />
                <LazyLoadImage
                  src={DexTools}
                  alt="DexTools_1"
                  className="hover-show ico_mobile"
                  style={{ width: "100px" }}
                />
              </Box>
            </section>
          </a>
          <a
            href="https://bnbproject.org/#/project/2274"
            aria-label="CryptoDaily"
            target="_blank"
          >
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={Bnbcoin} alt="Bnbcoin" />
                <LazyLoadImage
                  src={Bnbcoin_1}
                  alt="Bnbcoin"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://www.coinscope.co/coin/3-swt"
            target="_blank"
            aria-label="Coinscope"
          >
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={Coinscope} alt="Coinscope" />
                <LazyLoadImage
                  src={Coinscope_1}
                  alt="Coinscope"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://www.freshcoins.io/coins/sweettoken"
            target="_blank"
            aria-label="BitcoinForum"
          >
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={FreshCoin_1} alt="FreshCoin" />
                <LazyLoadImage
                  src={FreshCoin}
                  alt="FreshCoin"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://coinsniper.net/coin/40550"
            aria-label="Nipper"
            target="_blank"
          >
            <section className="partners-section nippers">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={Nipper} alt="Nipper" />
                <LazyLoadImage
                  src={Nipper_1}
                  alt="Nipper"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://coinhub.news/index.php/cs/article/bitcointalkann-sweetpad-first-double-audited-launchpad-with-nft-reflections-system?msclkid=90830027b03a11ec834c4bd535830241"
            target="_blank"
            aria-label="CoinHub"
          >
            <section className="partners-section coinHub-news">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={CoinHub} alt="CoinHub" />
                <LazyLoadImage
                  src={CoinHub_1}
                  alt="CoinHub"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://coinpaprika.com/coin/swt-sweettoken/"
            aria-label="Coinpaprika"
            target="_blank"
          >
            <section className="partners-section icomarks">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={Coinpaprika_1} alt="Coinpaprika" />
                <LazyLoadImage
                  src={Coinpaprika}
                  alt="Coinpaprika"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://cryptoinvest.am/blog/sweetpad-panel-zapuska-kripto-ido-sleduyushhego-pokoleniya"
            target="_blank"
            aria-label="Export"
          >
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={Export_1} alt="Export" />
                <LazyLoadImage
                  src={Export}
                  alt="Export"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://dappradar.com/binance-smart-chain/defi/sweettoken"
            aria-label="Dappradar"
            target="_blank"
          >
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={DapRadar_1} alt="Dappradar" />
                <LazyLoadImage
                  src={DapRadar}
                  alt="Dappradar"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://icoholder.com/en/sweetpad-1016830"
            aria-label="Icoholder"
            target="_blank"
          >
            <section className="partners-section">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={Icoholder} alt="Icoholder" />
                <LazyLoadImage
                  src={Icoholder_1}
                  alt="Icoholder"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://cryptotalk.org/topic/361333-ann-sweetpad-first-double-audited-launchpad-with-nft-reflections-system/"
            target="_blank"
            aria-label="Crypto"
          >
            <section className="partners-section cryptotalk">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={Crypto} alt="Crypto" />
                <LazyLoadImage
                  src={Crypto_1}
                  alt="Crypto"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://coinwire.com/sweetpad-your-sweetest-launchpad-is-launching-private-sale/"
            aria-label="Crypto"
            target="_blank"
          >
            <section className="partners-section cryptotalk">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={CryptoDaily} alt="Crypto" />
                <LazyLoadImage
                  src={CryptoDaily_1}
                  alt="Crypto"
                  className="hover-show"
                />
              </Box>
            </section>
          </a>
          <a
            href="https://www.livecoinwatch.com/price/SweetToken-__SWT"
            aria-label="LiveCoinWatch"
            target="_blank"
          >
            <section className="partners-section cryptotalk">
              <Box className={"image-box"}>
                <LazyLoadImage effect="blur" src={Livewatch_1} alt="LiveCoinWatch" />
                <LazyLoadImage src={Livewatch} alt="LiveCoinWatch" className="hover-show" />
              </Box>
            </section>
          </a>
        </>
      </Box>
    </Box>
  );
}
