import { MouseEvent, useState } from "react";
import "./footer.scss";
// import { useToast } from "@chakra-ui/react";
// import DiscordLogo from "../../svg/DiscordLogo";
// import FacebookLogo from "../../svg/FacebookLogo";
// import RedditLogo from "../../svg/RedditLogo";
// import Telegram from "../../svg/Telegram";
// import TikTokLogo from "../../svg/TikTokLogo";
// import TwitterLogo from "../../svg/TwitterLogo";
// import YoutubeLogo from "../../svg/YoutubeLogo";
// import InstagramLogo from "../../svg/InstagramLogo";
// import DiscordHover from "../../svg/discord-hover.png";
// import RedditHover from "../../svg/reddit-hover.png";
// import InstaHover from "../../icons/instagram-about.png";
// import { WalletStatus } from "../../types/types";
//
import TelegramLogoNew from "../../svg/social-desk/telegramDesk.svg";
import TwitterLogoNew from "../../svg/social-desk/twitterDesk.svg";
import DiscordLogoNew from "../../svg/social-desk/discordDesk.svg";
import RedditLogoNew from "../../svg/social-desk/redditDesk.svg";
import MediumLogoNew from "../../svg/social-desk/mediumDesk.svg";
import TikTokLogoNew from "../../svg/social-desk/tiktokDesk.svg";
import YoutubeLogoNew from "../../svg/social-desk/youtubeDesk.svg";
import FacebookLogoNew from "../../svg/social-desk/facebookDesk.svg";
import InstagramLogoNew from "../../svg/social-desk/instagramDesk.svg";
import BscScan from "../../svg/bscscan.svg";
import BscScanHover from "../../svg/bscHover.svg";

// filled logos

import DiscordLogoFilled from "../../svg/social-desk/discordDeskFill.svg";
import FacebookLogoFilled from "../../svg/social-desk/facebookDeskFill.svg";
import InstagramLogoFilled from "../../svg/social-desk/instagramDeskFill.svg";
import MediumLogoFilled from "../../svg/social-desk/mediumDeskFill.svg";
import RedditLogoFilled from "../../svg/social-desk/redditDeskFill.svg";
import TelegramFilled from "../../svg/social-desk/telegramDeskFill.svg";
import TiktokLogoFilled from "../../svg/social-desk/tiktokDeskFill.svg";
import TwitterLogoFilled from "../../svg/social-desk/twiiterDeskFill.svg";
import YoutubeLogoFilled from "../../svg/social-desk/youtubeDeskFill.svg";

import Logo from "../../svg/Logo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { Button, Box } from "@mui/material";

export interface State extends SnackbarOrigin {
  open: boolean;
}
const Footer = () => {
  const [state, setState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setState({ open: true, ...newState });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 2000);
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  // function openPopUp(event: MouseEvent) {
  //   event.preventDefault();

  //   if (countPopUp) {
  //     return false;
  //   }

  //   toast({
  //     status: WalletStatus.success,
  //     duration: 3000,
  //     isClosable: true,
  //     render: () =>
  //       !countPopUp ? <div className={"coming-soon"}>Coming Soon</div> : null,
  //   });

  //   setCountPopUp(true);

  //   setTimeout(() => setCountPopUp(false), 3000);
  // }

  // function openPopUpCoin(event: MouseEvent) {
  //   event.preventDefault();

  //   if (countPopUp) {
  //     return false;
  //   }

  //   toast({
  //     status: WalletStatus.success,
  //     duration: 3000,
  //     isClosable: true,
  //     render: () =>
  //       !countPopUp ? (
  //         <div className={"coming-soon"}>
  //           {" "}
  //           Proof Evidence - Untracked Listing
  //         </div>
  //       ) : null,
  //   });

  //   setCountPopUp(true);

  //   setTimeout(() => setCountPopUp(false), 3000);
  // }

  return (
    <>
      <Box className="footer-content">
        <div className={"footer"}>
          <div className=" footer-section">
            <section>
              <div className={"img-block-top"}>
                <Logo />
              </div>

              <div className={"footer-parent-section"}>
                <div className="footer-nav">
                  <ul>
                    <strong>About</strong>
                    <li>
                      <a
                        href="/SweetPadWhitepaper.pdf"
                        target={"_blank"}
                        aria-label="Whitepaper"
                      >
                        Whitepaper
                      </a>
                    </li>
                    <li>
                      <a
                        target={"_blank"}
                        href="https://bscscan.com/address/0xE8EbCf4Fd1faa9B77c0ec0B26e7Cc32a251Cd799"
                        aria-label="Token contract"
                      >
                        Token contract
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://coinmarketcap.com/currencies/sweetpad/"
                        target={"_blank"}
                        aria-label="Coinmarketcap"
                      >
                        CoinMarketCap
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://drive.google.com/drive/folders/1gRMjVdSOJzYZKvDBzFqo_1goE50JjwSr?usp=sharing"
                        target={"_blank"}
                        aria-label="Press Kit"
                      >
                        Press Kit
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.coingecko.com/en/coins/sweettoken"
                        target={"_blank"}
                        aria-label="Coingecko"
                      >
                        Coingecko
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <strong>Help</strong>

                    <li>
                      <a
                        href="https://t.me/+7sm5GRt8HLo3NGVi"
                        target={"_blank"}
                        aria-label="Support"
                      >
                        Support
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://sweetpad-1.gitbook.io/sweetpad-guides-and-faq/"
                        target={"_blank"}
                        aria-label="Guides"
                      >
                        Guides
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <strong>Developers</strong>

                    <li>
                      <a
                        className="buy-sweet-btn"
                        onClick={handleClick({
                          vertical: "top",
                          horizontal: "center",
                        })}
                      >
                        GitHub
                      </a>
                    </li>
                    <li>
                      <a
                        target={"_blank"}
                        href="https://irp.cdn-website.com/1b45ecb9/files/uploaded/SweetPad_AuditReport_InterFi.pdf"
                        className="buy-sweet-btn"
                        aria-label="Audits"
                      >
                        Audits
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="link-sections">
                <section className="footer-link">
                  <ul className="social-icons">
                    <section>
                      <li>
                        <a
                          href="https://bscscan.com/address/0xE8EbCf4Fd1faa9B77c0ec0B26e7Cc32a251Cd799"
                          aria-label="bscscan"
                          target={"_blank"}
                        >
                          <LazyLoadImage
                            src={BscScan}
                            alt="Twitter-logo"
                            className="twitter-hover"
                          />
                          <LazyLoadImage
                            src={BscScanHover}
                            alt="Twitter-logo"
                            className="twitter-filled"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={"https://twitter.com/SweetPad_"}
                          aria-label="TwitterLogo"
                          target={"_blank"}
                        >
                          <LazyLoadImage
                            src={TwitterLogoNew}
                            alt="Twitter-logo"
                            className="twitter-hover"
                          />
                          <LazyLoadImage
                            src={TwitterLogoFilled}
                            alt="Twitter-logo"
                            className="twitter-filled"
                          />
                        </a>
                      </li>
                      <li className="first">
                        <a
                          href={"https://t.me/sweetpadofficial"}
                          target={"_blank"}
                          aria-label="TelegramLogo"
                        >
                          <LazyLoadImage
                            src={TelegramLogoNew}
                            alt="Telegram-logo"
                            className="telegram-hover"
                          />
                          <LazyLoadImage
                            src={TelegramFilled}
                            alt="Telegram-logo"
                            className="telegram-filled"
                          />
                        </a>
                      </li>

                      <li>
                        <a
                          href={"https://discord.gg/T3R4MECCAG"}
                          aria-label="DiscordLogo"
                          target={"_blank"}
                        >
                          <LazyLoadImage
                            src={DiscordLogoNew}
                            alt="Discord-logo"
                            className="discord-hover"
                          />
                          <LazyLoadImage
                            src={DiscordLogoFilled}
                            alt="Discord-logo"
                            className="discord-filled"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            "https://www.facebook.com/SweetPad-official-111890581352736"
                          }
                          target={"_blank"}
                          aria-label="FacebookLogo"
                        >
                          <LazyLoadImage
                            src={FacebookLogoNew}
                            alt="facebook-logo"
                            className="facebook-hover"
                          />
                          <LazyLoadImage
                            src={FacebookLogoFilled}
                            alt="facebook-logo"
                            className="facebook-filled"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={"https://medium.com/@sweetpad.io.official"}
                          target={"_blank"}
                          aria-label="MediumLogo"
                        >
                          <LazyLoadImage
                            src={MediumLogoNew}
                            alt="Medium-logo"
                            className="medium-hover"
                          />
                          <LazyLoadImage
                            src={MediumLogoFilled}
                            alt="Medium-logo"
                            className="medium-filled"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            "https://instagram.com/sweetpad.io?utm_medium=copy_link"
                          }
                          target={"_blank"}
                          aria-label="InstagramLogo"
                        >
                          <LazyLoadImage
                            src={InstagramLogoNew}
                            alt="Insta-logo"
                            className="instagram-hover"
                          />
                          <LazyLoadImage
                            src={InstagramLogoFilled}
                            alt="Insta-logo"
                            className="instagram-filled"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={"https://www.reddit.com/r/SweetPad_Official/"}
                          target={"_blank"}
                          aria-label="RedditLogo"
                        >
                          <LazyLoadImage
                            src={RedditLogoNew}
                            alt="Reddit-Logo"
                            className="reddit-hover"
                          />
                          <LazyLoadImage
                            src={RedditLogoFilled}
                            alt="Reddit-Logo"
                            className="reddit-filled"
                          />
                        </a>
                      </li>

                      <div className="socials_wrap">
                        <li>
                          <a
                            href={
                              "https://youtube.com/channel/UCVlg4Abe1XIWbXYyD6aMixA"
                            }
                            target={"_blank"}
                            aria-label="YoutubeLogo"
                          >
                            <LazyLoadImage
                              src={YoutubeLogoNew}
                              alt="Telegram-logo"
                              className="youtube-hover"
                            />
                            <LazyLoadImage
                              src={YoutubeLogoFilled}
                              alt="Telegram-logo"
                              className="youtube-filled"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={"https://vm.tiktok.com/ZSeahCPgn/"}
                            target={"_blank"}
                            aria-label="TikTokLogo"
                          >
                            <LazyLoadImage
                              src={TikTokLogoNew}
                              alt="tiktok-Logo"
                              className="tiktok-hover"
                            />
                            <LazyLoadImage
                              src={TiktokLogoFilled}
                              alt="tiktok-Logo"
                              className="tiktok-filled"
                            />
                          </a>
                        </li>
                      </div>
                    </section>
                  </ul>
                </section>
              </div>
              <div className={"img-block-mobile"}>
                <Logo />
              </div>
              <div className="line"></div>
              <div className="line-footer">
                <p className={"copyright"}>
                  Copyright © 2022 | All rights reserved
                </p>
              </div>
            </section>
          </div>
        </div>
      </Box>
      <Snackbar
        className="popup-soon"
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="Coming soon"
        key={vertical + horizontal}
      />
    </>
  );
};
export default Footer;
