import { useEffect, useState } from "react";
// import { Button, useToast } from "@chakra-ui/react";
// import Tiktok from "../../../svg/tiktok.png";
// import Web3EthContract from "web3-eth-contract";
import { Box, Grid, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import DragonVideo from "../../DragonVideo";
import { Button } from "@mui/material";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import SweetFreezingTest_abi from "../../../utils/ABI/sweet-freezing.json";
import { sweetFreezing } from "../../../utils/contractaddresses";
//
//New head socials

import DiscordNew from "../../../svg/social-desk/discordDesk.svg";
import FacebookNew from "../../../svg/social-desk/facebookDesk.svg";
import InstagramNew from "../../../svg/social-desk/instagramDesk.svg";
import MediumNew from "../../../svg/social-desk/mediumDesk.svg";
import RedditNew from "../../../svg/social-desk/redditDesk.svg";
import TelegramNew from "../../../svg/social-desk/telegramDesk.svg";
import TiktokNew from "../../../svg/social-desk/tiktokDesk.svg";
import TwitterNew from "../../../svg/social-desk/twitterDesk.svg";
import YoutubeNew from "../../../svg/social-desk/youtubeDesk.svg";

//filled socials

import DiscordNewFilled from "../../../svg/social-desk/discordDeskFill.svg";
import FacebookNewFilled from "../../../svg/social-desk/facebookDeskFill.svg";
import InstagramNewFilled from "../../../svg/social-desk/instagramDeskFill.svg";
import MediumNewFilled from "../../../svg/social-desk/mediumDeskFill.svg";
import RedditNewFilled from "../../../svg/social-desk/redditDeskFill.svg";
import TelegramNewFilled from "../../../svg/social-desk/telegramDeskFill.svg";
import TiktokNewFilled from "../../../svg/social-desk/tiktokDeskFill.svg";
import TwitterNewFilled from "../../../svg/social-desk/twiiterDeskFill.svg";
import YoutubeNewFilled from "../../../svg/social-desk/youtubeDeskFill.svg";

//mobile socials

// import DiscordNewMobile from "../../../svg/Discord2Mobile.svg";
// import FacebookNewMobile from "../../../svg/FB2Mobile.svg";
// import InstagramNewMobile from "../../../svg/Instagram2Mobile.svg";
// import MediumNewMobile from "../../../svg/Medium2Mobile.svg";
// import RedditNewMobile from "../../../svg/Reddit2Mobile.svg";
// import TelegramNewMobile from "../../../svg/Telegram2Mobile.svg";
// import TiktokNewMobile from "../../../svg/TikTok2Mobile.svg";
// import TwitterNewMobile from "../../../svg/TwitterHeader.svg";
// import YoutubeNewMobile from "../../../svg/youtube1Mobile.svg";

import DiscordNewMobile from "../../../svg/social_mobile/discordDeskMobile.svg";
import FacebookNewMobile from "../../../svg/social_mobile/facebookDeskMobile.svg";
import InstagramNewMobile from "../../../svg/social_mobile/instagramDeskMobile.svg";
import MediumNewMobile from "../../../svg/social_mobile/mediumDeskMobile.svg";
import RedditNewMobile from "../../../svg/social_mobile/redditDeskMobile.svg";
import TelegramNewMobile from "../../../svg/social_mobile/telegramDeskMobile.svg";
import TiktokNewMobile from "../../../svg/social_mobile/tiktokDeskMobile.svg";
import TwitterNewMobile from "../../../svg/social_mobile/twitterDeskMobile.svg";
import YoutubeNewMobile from "../../../svg/social_mobile/youtubeDeskMobile.svg";
import BscScan from "../../../svg/bscscan.svg";
import BscHover from "../../../svg/bscHover.svg";
import BscHoverMobile from "../../../svg/bscHoverMobile.svg";
import BscScanMobile from "../../../svg/bscScanMobile.svg";

//test

//mobile filled socials

// import DiscordNewFilledMobile from "../../../svg/Discord1mobile.svg";
// import FacebookNewFilledMobile from "../../../svg/FB2mobile.svg";
// import InstagramNewFilledMobile from "../../../svg/Instagram1mobile.svg";
// import MediumNewFilledMobile from "../../../svg/Medium1mobile.svg";
// import RedditNewFilledMobile from "../../../svg/Reddit1mobile.svg";
// import TelegramNewFilledMobile from "../../../svg/Telegram1mobile.svg";
// import TiktokNewFilledMobile from "../../../svg/TikTok1mobile.svg";
// import TwitterNewFilledMobile from "../../../svg/twiiterHeader.svg";
// import YoutubeNewFilledMobile from "../../../svg/YT2mobile.svg";

import DiscordNewFilledMobile from "../../../svg/social_mobile/discordDeskFill.svg";
import FacebookNewFilledMobile from "../../../svg/social_mobile/facebookDeskFill.svg";
import InstagramNewFilledMobile from "../../../svg/social_mobile/instagramDeskFill.svg";
import MediumNewFilledMobile from "../../../svg/social_mobile/mediumDeskFill.svg";
import RedditNewFilledMobile from "../../../svg/social_mobile/redditDeskFill.svg";
import TelegramNewFilledMobile from "../../../svg/social_mobile/telegramDeskFill.svg";
import TiktokNewFilledMobile from "../../../svg/social_mobile/tiktokDeskFill.svg";
import TwitterNewFilledMobile from "../../../svg/social_mobile/twiiterDeskFill.svg";
import YoutubeNewFilledMobile from "../../../svg/social_mobile/youtubeDeskFill.svg";
//swt coins for animation

import { useDispatch } from "react-redux";
import { SocialActionCreators } from "../../../store/reducers/socials/action-creators";
import Swt1 from "../../../svg/swt1.svg";
import Swt2 from "../../../svg/swt2.svg";
import Swt3 from "../../../svg/swt3.svg";
import BuySWT from "./BuySWT";

interface Iprops {
  connectWallet: (boll: boolean) => void;
}

const About = ({ connectWallet }: Iprops) => {
  const dispatch = useDispatch();
  //   const [unique, setUnique] = useState<any>([]);
  const [lpcount, setLpcount] = useState<null | string>(null);
  const [swt, swtSwtCount] = useState<null | string | number>(null);
  const { connected, usersStakes, freezeInfo } = useTypedSelector(
    (state) => state.socials
  );

  const asyncGetData = (data) => {
    let len = data.length;

    let response: any = [];

    return new Promise((resolve, reject) => {
      try {
        data.map((item) => {
          item.then((res) => {
            response.push(res);
          });
        });

        let interval = setInterval(() => {
          if (len === response.length) {
            clearInterval(interval);
            resolve(response);
          }
        }, 500);
      } catch (err) {
        reject([]);
      }
    });
  };

  const Contract = require("web3-eth-contract");
  useEffect(() => {
    const provider = new Web3(
      new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org")
    );

    const getSwtLp = async () => {
      Contract.setProvider(provider);
      const contract: any = new Contract(
        SweetFreezingTest_abi as AbiItem[],
        sweetFreezing
      );
      if (contract) {
        let dataStakes = usersStakes.map((element) => {
          return contract.methods
            .freezeInfo(element.attributes.account, "0")
            .call();
        });

        let data = await dataStakes;

        let sendAllRequest = await asyncGetData(data);

        if (!!usersStakes && !!sendAllRequest) {
          dispatch(SocialActionCreators.setFreezeInfo(sendAllRequest));
        }
      }

      let swt = await contract.methods.totalFrozenSWT().call();

      let swtLast = await Web3.utils.fromWei(swt);
      swtSwtCount(swtLast);
      let lp: any = await contract.methods.totalFrozenLP().call();
      let lastLp = await Web3.utils.fromWei(lp);

      setLpcount(lastLp);
    };
    getSwtLp();
  }, [usersStakes]);

  const duplicateIds = usersStakes
    .map((v) => v.attributes["account"])
    .filter((v, i, vIds) => vIds.indexOf(v) !== i);
  const duplicates = usersStakes.filter((obj) =>
    duplicateIds.includes(obj.attributes["account"])
  );

  let unique = usersStakes
    .map((e) => e.attributes["account"])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((obj) => usersStakes[obj])
    .map((e) => usersStakes[e]);

  // useEffect(() => {
  //   const getSweetCoinData = async () => {
  //     let sweetContract = await new provider.eth.Contract(
  //       SweetFreezingTest_abi as AbiItem[],
  //       sweetFreezing
  //     );
  //     setContract(sweetContract);
  //     let perDays = await sweetContract.methods.getBlocksPerDay().call();
  //     let a = await sweetContract.methods
  //       .getPower(provider.utils.toWei(sweetCoin), days * perDays)
  //       .call();

  //   };
  //   getSweet();
  // }, []);
  return (
    <Grid
      container
      spacing={2}
      marginTop={"80px"}
      className="content-container-about"
    >
      <Grid item className="columns" md={8}>
        <Grid md={9} className={"about-header-parent"}>
          <Typography className="about-header" id={"about-header"}>
            WELCOME TO RAISING AND OWNING YOUR FUTURE
          </Typography>
        </Grid>
        <Grid
          md={11.5}
          className={"about-header-parent about-header-sub-parent "}
        >
          <Typography className="about-text">
            We are a launchpad integrated with a double audit system making it
            secure and safe for pro and beginner investors. Staking tokens and
            NFTs feature for greater values, running on BSC with Tax-Free.
          </Typography>
        </Grid>
        <Grid item md={6} sm={6} xs={12} className="stack-card stack-mobile">
          <div className="stack-values">
            <div>
              <span>TOTAL VALUE STAKING</span>

              <strong>$ {Number(swt) / 2 || 207545}</strong>
            </div>

            <div className="stake-unique">
              <span>UNIQUE STAKERS</span>
              <strong>{unique.length}</strong>
            </div>
            <div>
              <span>SWT STAKED</span>
              <strong>{swt || 415090} SWT</strong>
            </div>
            <div className="stake-unique">
              <span>SWT-LP STAKED</span>
              <strong>{(lpcount && lpcount) || 0}</strong>
            </div>
          </div>
        </Grid>

        <Box className="buy-sweet-open">
          <a
            target={"_blank"}
            href="https://docs.google.com/forms/d/e/1FAIpQLSeN_kWm2SLO842TtFXVux1ZnXzOeO50uLgl7mLm25UalvLvMA/viewform"
          >
            <Button className="button-ido">Apply for IDO</Button>
          </a>
          <BuySWT />
          {/* <Button
            className="buy-sweet-btn"
            onClick={handleClick({
              vertical: "top",
              horizontal: "center",
            })}
          >
            Buy SWT
          </Button> */}
          {/* <Snackbar
            className="popup-soon"
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Coming soon"
            key={vertical + horizontal}
          /> */}

          {/* {localAddress || localAddressState ? (
            <Button
              className="buy-sweet-btn"
              //  onClick={openPopUp}
            >
              Buy SWT
            </Button>
          ) : (
          )} */}
        </Box>
        <Box className="icons-and-animate-box">
          <Box className="social-icons-header">
            <a
              href="https://bscscan.com/address/0xE8EbCf4Fd1faa9B77c0ec0B26e7Cc32a251Cd799"
              target={"_blank"}
              // aria-label="TwitterNew"
            >
              <LazyLoadImage
                src={BscScan}
                // alt="Twitter"
                className="hide-hover"
              />
              <LazyLoadImage
                src={BscHover}
                // alt="Twitter"
                className="show-hover"
              />

              {/*<TwitterHeader/>*/}
            </a>
            <a
              href="https://twitter.com/SweetPad_"
              target={"_blank"}
              aria-label="TwitterNew"
            >
              <LazyLoadImage
                src={TwitterNew}
                alt="Twitter"
                className="hide-hover"
              />
              <LazyLoadImage
                src={TwitterNewFilled}
                alt="Twitter"
                className="show-hover"
              />
            </a>
            <a
              href="https://t.me/sweetpadofficial"
              target={"_blank"}
              aria-label="TelegramNew"
            >
              <LazyLoadImage
                src={TelegramNew}
                alt="Telegram"
                className="hide-hover"
              />
              <img
                src={TelegramNewFilled}
                alt="Telegram"
                className="show-hover"
              />
            </a>
            <a
              href="https://discord.com/invite/T3R4MECCAG"
              target={"_blank"}
              aria-label="DiscordNew"
            >
              <LazyLoadImage
                src={DiscordNew}
                alt="Discord"
                className="hide-hover"
              />

              <LazyLoadImage
                src={DiscordNewFilled}
                alt="Discord"
                className="show-hover"
              />
            </a>

            <a
              href="https://www.facebook.com/SweetPad-official-111890581352736"
              target={"_blank"}
              aria-label="FacebookNew"
            >
              <LazyLoadImage
                src={FacebookNew}
                alt="Facebook"
                className="hide-hover"
              />
              <LazyLoadImage
                src={FacebookNewFilled}
                alt="Facebook"
                className="show-hover"
              />
            </a>
            <a
              href="https://medium.com/@sweetpad.io.official"
              target={"_blank"}
              className="medium-hover"
              aria-label="MediumNew"
            >
              <LazyLoadImage
                src={MediumNew}
                alt="medium"
                className="hide-hover"
              />
              <LazyLoadImage
                src={MediumNewFilled}
                alt="medium"
                className="show-hover"
              />
            </a>
            <a
              href="https://instagram.com/sweetpad.io?utm_medium=copy_link "
              target={"_blank"}
              aria-label="InstagramNew"
            >
              <LazyLoadImage
                src={InstagramNew}
                alt="Instagram"
                className="hide-hover"
              />
              <LazyLoadImage
                src={InstagramNewFilled}
                alt="Instagram"
                className="show-hover"
              />
            </a>
            <a
              href="https://www.reddit.com/r/SweetPad_Official/"
              target={"_blank"}
              aria-label="RedditNew"
            >
              <LazyLoadImage
                src={RedditNew}
                alt="Reddit"
                className="hide-hover"
              />
              <LazyLoadImage
                src={RedditNewFilled}
                alt="Reddit"
                className="show-hover"
              />
            </a>
            <a
              href="https://youtube.com/channel/UCVlg4Abe1XIWbXYyD6aMixA"
              target={"_blank"}
              aria-label="YoutubeNew"
            >
              <LazyLoadImage
                src={YoutubeNew}
                alt="Youtube"
                className="hide-hover"
              />
              <LazyLoadImage
                src={YoutubeNewFilled}
                alt="Youtube"
                className="show-hover"
              />
              {/*<YoutubeLogo/>*/}
            </a>
            <a
              href="https://www.tiktok.com/@sweetpadofficial?_t=8W42D0yzg9J&_r=1"
              target={"_blank"}
              aria-label="TiktokNew"
            >
              <LazyLoadImage
                src={TiktokNew}
                alt="Tiktok"
                className="hide-hover"
              />
              <LazyLoadImage
                src={TiktokNewFilled}
                alt="Tiktok"
                className="show-hover"
              />
            </a>
          </Box>
          <Box className="swt-coins">
            <img src={Swt1} alt="Swt1" className="swt1" />
            <img src={Swt2} alt="Swt2" className="swt2" />
            <img src={Swt3} alt="Swt3" className="swt3" />
          </Box>
        </Box>

        {/*//icons for mobile*/}

        <Box className="icons-and-animate-box-mobile">
          <Box className="social-icons-header-mobile">
            <a
              href="https://bscscan.com/address/0xE8EbCf4Fd1faa9B77c0ec0B26e7Cc32a251Cd799"
              target={"_blank"}
              aria-label="bscscan"
            >
              <LazyLoadImage
                src={BscScanMobile}
                alt="bscscan"
                className="hide-hover"
              />
              <LazyLoadImage
                src={BscHoverMobile}
                alt="Twitter"
                className="show-hover"
              />

              {/*<TwitterHeader/>*/}
            </a>
            <a
              href="https://twitter.com/SweetPad_"
              target={"_blasetUsersStakersnk"}
              aria-label="TwitterNew"
            >
              <LazyLoadImage
                src={TwitterNewMobile}
                alt="Twitter"
                className="hide-hover"
              />
              <LazyLoadImage
                src={TwitterNewFilledMobile}
                alt="Twitter"
                className="show-hover"
              />
            </a>
            <a
              href="https://t.me/sweetpadofficial"
              target={"_blank"}
              aria-label="TelegramNew"
            >
              <LazyLoadImage
                src={TelegramNewMobile}
                alt="Telegram"
                className="hide-hover"
              />
              <LazyLoadImage
                src={TelegramNewFilledMobile}
                alt="Telegram"
                className="show-hover"
              />
            </a>
            <a
              href="https://discord.com/invite/T3R4MECCAG"
              target={"_blank"}
              aria-label="DiscordNew"
            >
              <LazyLoadImage
                src={DiscordNewMobile}
                alt="Discord"
                className="hide-hover"
              />
              <LazyLoadImage
                src={DiscordNewFilledMobile}
                alt="Discord"
                className="show-hover"
              />

              {/*<DiscordHeader/>*/}
            </a>

            <a
              href="https://www.facebook.com/SweetPad-official-111890581352736"
              target={"_blank"}
              aria-label="FacebookNew"
            >
              {/*<FacebookLogo />*/}
              <LazyLoadImage
                src={FacebookNewMobile}
                alt="Facebook"
                className="hide-hover"
              />
              <LazyLoadImage
                src={FacebookNewFilledMobile}
                alt="Facebook"
                className="show-hover"
              />
            </a>
            <a
              href="https://medium.com/@sweetpad.io.official"
              target={"_blank"}
              className="medium-hover"
              aria-label="MediumNew"
            >
              <LazyLoadImage
                src={MediumNewMobile}
                alt="medium"
                className="hide-hover"
              />
              <LazyLoadImage
                src={MediumNewFilledMobile}
                alt="medium"
                className="show-hover"
              />
            </a>
            <a
              href="https://instagram.com/sweetpad.io?utm_medium=copy_link "
              target={"_blank"}
              aria-label="InstagramNew"
            >
              <LazyLoadImage
                src={InstagramNewMobile}
                alt="Instagram"
                className="hide-hover"
              />
              <LazyLoadImage
                src={InstagramNewFilledMobile}
                alt="Instagram"
                className="show-hover"
              />
            </a>
            <a
              href="https://www.reddit.com/r/SweetPad_Official/"
              target={"_blank"}
              aria-label="RedditNew"
            >
              <LazyLoadImage
                src={RedditNewMobile}
                alt="Reddit"
                className="hide-hover"
              />
              <LazyLoadImage
                src={RedditNewFilledMobile}
                alt="Reddit"
                className="show-hover"
              />
            </a>
            <a
              href="https://youtube.com/channel/UCVlg4Abe1XIWbXYyD6aMixA"
              target={"_blank"}
              aria-label="YoutubeNew"
            >
              {/**/}
              <LazyLoadImage
                src={YoutubeNewMobile}
                alt="Youtube"
                className="hide-hover"
              />
              <LazyLoadImage
                src={YoutubeNewFilledMobile}
                alt="Youtube"
                className="show-hover"
              />
            </a>
            <a
              href="https://www.tiktok.com/@sweetpadofficial?_t=8W42D0yzg9J&_r=1"
              target={"_blank"}
              aria-label="TiktokNew"
            >
              {/*<TikTokLogo />*/}
              <LazyLoadImage
                src={TiktokNewMobile}
                alt="Tiktok"
                className="hide-hover"
              />
              <LazyLoadImage
                src={TiktokNewFilledMobile}
                alt="Tiktok"
                className="show-hover"
              />
            </a>
          </Box>
          <Box className="swt-coins">
            <img src={Swt1} alt="Swt1" className="swt1" />
            <img src={Swt2} alt="Swt2" className="swt2" />
            <img src={Swt3} alt="Swt3" className="swt3" />
          </Box>
        </Box>
      </Grid>
      {/* <div className="columns">
        <div className="cust-container">
          <img src={pic} alt="Avatar" style={{ width: "400px", zIndex: 10 }} />
        </div>
      </div> */}
      <Grid item md={4} className="stack-card">
        <div className="stack-values">
          <div>
            <span>TOTAL VALUE STAKING</span>

            <strong>$ {Number(swt) / 2}</strong>
          </div>

          <div className="stake-unique">
            <span>UNIQUE STAKERS</span>
            <strong>{unique.length}</strong>
          </div>
          <div>
            <span>SWT STAKED</span>
            <strong>{swt || 415090} SWT</strong>
          </div>
          <div className="stake-unique">
            <span>SWT-LP STAKED</span>
            <strong>{(lpcount && lpcount) || 0}</strong>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
export default About;
