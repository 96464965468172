import { Box } from "@mui/material";

interface Iprops {
  title: any;
  active: boolean;
  id: number;
  getID: (id: any) => void;
  sweetCount: any;
  sweetPercent: (sweetCount: any) => any;
}
export default function Liner({
  title,
  id,
  active,
  getID,
  sweetCount,
  sweetPercent,
}: Iprops):JSX.Element {
  return (
    <Box
      className="percentage"
      onClick={() => {
        getID(id);
        sweetPercent((sweetCount * title) / 100);
      }}
    >
      <Box className={active ? "percent-active" : "percent-inactive"}></Box>
      <span>{title}%</span>
    </Box>
  );
}
