import React from "react";
import DaysCalc from "../SWT/days-calc";
import Switch from "./Switch";
// import DaysCalc from "../SWT/days-calc";
import { Typography, Button, Box } from "@mui/material";

interface Iprops {
  isDisabledMinus: boolean;
  handleDeCrease: () => void;
  isDisabledPlus: boolean;
  days: number;
  handleInCrease: () => void;
  handleChangeDays: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlurDays: (e: any) => void;
  warningText: string;
  result: number;
}
export default function Liquidity({
  isDisabledMinus,
  handleDeCrease,
  isDisabledPlus,
  days,
  handleInCrease,
  handleChangeDays,
  handleBlurDays,
  warningText,
  result,
}: Iprops) {

  const handleClick = (e) => {
    e.target.innerText='Add Liquidity & Stake'
  }

  return (
    <Box className="add-liquidity">
      <Typography component={"h3"} className="text-title-liquidity">
        By adding your liquidity calculate how many tokens you will recieve
        depending on your lock time.
      </Typography>
      {/* <Box className="wbnb-swt">
        <Switch />
        <Button className="max-liq">Max</Button>
      </Box> */}
      <Switch />

      {/* <Input placeholder={"0.00 WBNB  "} /> */}
      {/* <Input placeholder="0.00 LP" /> */}
      <DaysCalc
        isDisabledMinus={isDisabledMinus}
        handleDeCrease={handleDeCrease}
        isDisabledPlus={isDisabledPlus}
        handleInCrease={handleInCrease}
        days={days}
        handleChangeDays={handleChangeDays}
        // handleBlurDays={handleBlurDays}
        warningText={warningText}
        result={result}
      />
      <div className="add-liq-stack buttons-group liquid_button">
        <Button onClick={(e) =>  handleClick(e)}> Approve</Button>
      </div>
    </Box>
  );
}
