import React, { useState } from "react";
import { Button } from "@mui/material";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";

export interface State extends SnackbarOrigin {
  open: boolean;
}
export default function BuySWT() {
  const [state, setState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setState({ open: true, ...newState });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 2000);
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <>
      <a
        className="buy-sweet-btn-href"
        target={"_newblank"}
        href="https://apeswap.finance/swap"
      >
        <Button
          className="buy-sweet-btn"
          // onClick={handleClick({
          //   vertical: "top",
          //   horizontal: "center",
          // })}
        >
          Buy SWT
        </Button>
      </a>
      {/* <Snackbar
        className="popup-soon"
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="Coming soon"
        key={vertical + horizontal}
      /> */}
    </>
  );
}
