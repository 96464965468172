
import * as PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { SocialActionCreators } from "../../../../store/reducers/socials/action-creators";

import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { Box, Button } from "@mui/material";
import Drawwer from "../../AccountDrawer";


interface IHeaderProps {
  killSession: () => void;
  connected: boolean | undefined;
  address: string | undefined;
  chainId: number;
  accountLocal: any;
  onConnect: () => void;
}

const Header = (props: IHeaderProps) => {
  const { openBurgerMenu, drawwerMenu } = useTypedSelector(
    (state) => state.socials
  );
  const dispatch = useDispatch();
  // @ts-ignore

  const { killSession, onConnect, address } = props;

  function killSessionCall() {
    killSession();
    localStorage.clear();
    dispatch(SocialActionCreators.setIsAddresConnected(false));

    dispatch(SocialActionCreators.setIsConnectButtonClicked(false));
    dispatch(SocialActionCreators.setConnected(false));

    // onClose();
  }
  function handleOpen() {
    dispatch(SocialActionCreators.setOpenDrawerMenu(true));
    if (openBurgerMenu) {
      dispatch(SocialActionCreators.setOpenBurgerMenu(false));
    }
  }
  let newAddres = address;
  if (!newAddres) {
    let test = JSON.parse(localStorage.getItem("walletconnect") || "{}");
    newAddres = test.accounts !== undefined ? test.accounts[0] : address;
  }


  const handleClose = () => {
    dispatch(SocialActionCreators.setOpenDrawerMenu(false));
  };

  return (
    <Box className="connected-wallet-p">
      {props.connected || newAddres ? (
        <Box className="account-div">
          <Button type="button"
                  onClick={handleOpen}
                  className="my-account">
            My Account
          </Button>
          <Drawwer
            anchor="right"
            open={drawwerMenu}
            onClose={handleClose}
            killSessionCall={killSessionCall}
          />
        </Box>
      ) : (
        <>
          <Button onClick={onConnect} className={"connect-btn-wallet"}>
            Connect Wallet
          </Button>
        </>
      )}
    </Box>
  );
};

Header.propTypes = {
  killSession: PropTypes.func.isRequired,
  address: PropTypes.string,
};

export default Header;
