import React from "react";
import VideoSweetVideo from "./sweet-video.mp4";

export default function VideoSweetElem({ show }) {
  return (
    <video
      width="100%"
      controls
      autoPlay={show}
      className="sweet-video"
      height={"100%"}
    >
      <source src={VideoSweetVideo} type="video/mp4" />
    </video>
  );
}
