import React, { useState, useEffect } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Calculator from "./calculator";
import { Box, Typography } from "@mui/material";
import MySlide from "./3dSlider/MySlide";

const Tires = () => {
  const [days, setDays] = useState<number>(365);
  const [card, setCard] = useState<string>("The Skater");
  const { result, disabledTab } = useTypedSelector((state) => state.socials);
  const changeCardHandler = (card: string, num: number) => {
    setCard((card) => {
      return card;
    });
  };
  useEffect(() => {
    renderComponent();
  }, [disabledTab]);
  const renderComponent = () => {
    // @ts-ignore
    return disabledTab ? <MySlide /> : <></>;
  };

  return (
    <div className="tires-calc">
      <Typography className="pool-title" id="calculator-id">
        Calculator
      </Typography>
      <section className="content-container">
        <div data-aos="fade-right" className="columns calc-columns">
          <div className="tire-container">
            <Calculator
              days={days}
              setDays={(value) => {
                setDays(value);
              }}
              changeCardHandler={(card, num) => {
                changeCardHandler(card, num);
              }}
              setCard={(card) => {
                setCard(card);
              }}
            />
          </div>
        </div>

        <div data-aos="fade-left" className="columns col-calculator">
          <div
            className="tire-container tire-cards"
            style={{ display: "block" }}
          >
            {renderComponent()}
          </div>
        </div>
      </section>
    </div>
  );
};
export default Tires;
