import { Box, Grid, Typography } from "@mui/material";

import { ReactComponent as LockedSvg } from "../../../svg/LockTokeno.svg";

const Tokeno = () => {
  return (
    <Grid spacing={2} className={"tokeno"}>
      <Box className={"title-section"}>
        <Box className={"title-tokeno pool-title"} id="tokenomics">
          <Typography>Tokenomics</Typography>
          <Typography className="total-tokeno">
            {" "}
            Total Supply: 100.000.000 SWT
          </Typography>
        </Box>
      </Box>
      <Box className={"card-precent"}>
        <Box className={"parent-item-card"}>
          <Box className={"parent-item-card-center"}>
            <Box className={"item-card item-card-margin item-card-mobile"}>
              <Typography className={"item-title"}>
                Reserve
                <Typography>25%</Typography>
              </Typography>
              <Box className={"liner"}>
                <Box className="locked-tokens">
                  <LockedSvg />
                  <span>Locked</span>
                </Box>
                <Typography className={"item-precent25"}></Typography>
              </Box>
            </Box>
            <Box className={"item-card item-card-margin"}>
              <Typography className={"item-title"}>
                Team <Typography>19%</Typography>
              </Typography>
              <Box className={"liner"}>
                <Box className="locked-tokens">
                  <LockedSvg />
                  <span>Locked</span>
                </Box>
                <Typography className={"item-precent19"}></Typography>
              </Box>
            </Box>
            <Box className={"item-card item-card-margin"}>
              <Box className={"item-title"}>
                Liquidity Pool Reserve
                <Typography>18%</Typography>
              </Box>
              <Box className={"liner"}>
                <Box className="locked-tokens">
                  <LockedSvg />

                  <span>Locked</span>
                </Box>
                <Typography className={"item-precent18"}></Typography>
              </Box>
            </Box>
            <Box className={"item-card"}>
              <Typography className={"item-title"}>
                P2E Lock
                <Typography>15%</Typography>
              </Typography>
              <Box className={"liner"}>
                <Box className="locked-tokens">
                  <LockedSvg />

                  <span>Locked</span>
                </Box>
                <Typography className={"item-precent15"}></Typography>
              </Box>
            </Box>
            <Box className={"item-card"}>
              <Typography className={"item-title"}>
                Marketing
                <Typography>9%</Typography>
              </Typography>
              <Box className={"liner"}>
                <Box className="locked-tokens">
                  <LockedSvg />

                  <span>Locked</span>
                </Box>
                <Typography className={"item-precent9"}></Typography>
              </Box>
            </Box>
            <Box className={"item-card"}>
              <Typography className={"item-title"}>
                CEX Listing
                <Typography>5%</Typography>
              </Typography>
              <Box className={"liner"}>
                <Typography className={"item-precent5"}></Typography>
                <Box className="locked-tokens">
                  <LockedSvg />

                  <span>Locked</span>
                </Box>
              </Box>
            </Box>
            <Box className={"item-card item-card-margin-btm"}>
              <Typography className={"item-title"}>
                Advisors
                <Typography>4%</Typography>
              </Typography>
              <Box className={"liner"}>
                <Box className="locked-tokens">
                  <LockedSvg />
                  <span>Locked</span>
                </Box>
                <Typography className={"item-precent4"}></Typography>
              </Box>
            </Box>
            <Box className={"item-card item-card-margin-btm"}>
              <Typography className={"item-title"}>
                Seed/Private Sale
                <Typography>4%</Typography>
              </Typography>
              <Box className={"liner"}>
                <Typography className={"item-precent4"}></Typography>
              </Box>
            </Box>
            <Box className={"item-card item-card-margin-btm"}>
              <Typography className={"item-title"}>
                Charity
                <Typography>1%</Typography>
              </Typography>
              <Box className={"liner"}>
                <Box className="locked-tokens">
                  <LockedSvg />

                  <span>Locked</span>
                </Box>
                <Typography className={"item-precent1"}></Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        style={{ marginTop: "30px" }}
        id={"roadmap-scroll-mobile"}
        className="roadmap-scroll-mobile"
      ></Box>
      <Typography className="pool-title-roadmap" id={"roadmap-scroll"}>
        Roadmap
      </Typography>
    </Grid>
  );
};
export default Tokeno;
