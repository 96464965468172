import { Box, ClickAwayListener, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Logo from "../../../svg/Logo";
import WalletConnect from "../../walletConnect";
import About from "../about";
import InfoContents from "../infoContents";
import "./header.scss";


interface Iprops {
  connectWallet: (bool: boolean) => void;
  menu: boolean;
  setMenu: (menu: boolean) => void;
}
const Header = ({ connectWallet, menu, setMenu }: Iprops) => {
  function scrollSmoothTo(elementId: string) {
    let element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
 
  }

  //function for show and hide header on scroll
  //added by Harut
  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState("");
    useEffect(() => {
      let lastScrollY = window.pageYOffset;
      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;

        if (lastScrollY == 0 || lastScrollY < 200) {
          setScrollDirection("a");
        }
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      };
    }, [scrollDirection]);

    return scrollDirection;
  }

  const scrollDirection = useScrollDirection();

  const handleClickAway = () => {
    setMenu(false);
  };

  return (
    <>
      <Grid>
        <Box
          className={
            scrollDirection === "down"
              ? "row-header-bg"
              : menu
              ? "header-noneBackgr"
              : "row-header-bg-fixed"
          }
        >
          <Box className="page-content rowHeader ">
            <Grid item className="d-flex" lg={1} sm={3}>
              <Logo />
            </Grid>
            <Grid item lg={11} sm={3} className="nav-and-connect">
              <InfoContents
                setMenu={setMenu}
                menu={menu}
                scrollSmoothTo={scrollSmoothTo}
              />
              <WalletConnect
                connectWallet={(bool: boolean) => connectWallet(bool)}
              />
            </Grid>

            <Grid
              item
              sm={3}
              className={`burger`}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 6 }}
                  className="icon-button"
                  onClick={() => setMenu(!menu)}
                >
                  <Box
                    className={`burger-line burger-line1 ${
                      menu ? "rotate1" : "rotate-back1"
                    }`}
                  ></Box>
                  <Box
                    className={`burger-line burger-line2 ${menu ? "open" : ""}`}
                  ></Box>
                  <Box
                    className={`burger-line burger-line3 ${
                      menu ? "rotate2" : "rotate-back2"
                    }`}
                  ></Box>
                </IconButton>
              </ClickAwayListener>
            </Grid>
          </Box>
        </Box>
        <Box
          className="page-content"
        >
          <About connectWallet={connectWallet} />
        </Box>
      </Grid>
    </>
  );
};
export default Header;
