import { NavLink, useLocation } from "react-router-dom";
import logo from "../svg/HeaderLogo.svg";
import logoMg from "../svg/logo-mobile.svg";
export default function Logo() {
  const location = useLocation();
  function scrollSmoothTo(elementId) {
    let element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }
  return (
    <NavLink to={"/"} className="info-item-h2">
      {location.pathname == "/apeswap" ? (
        <>
          <img className="logo-sweet-desktop" src={logo} alt="logo" />
        </>
      ) : (
        <>
          <img className="logo-sweet-desktop" src={logo} alt="logo" />

          <img className="logo-sweet-mobile" src={logoMg} alt="logo" />
        </>
      )}
    </NavLink>
  );
}
