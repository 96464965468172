import React, { useEffect, useState } from "react";
import { Box, Button, Input, Modal, Typography } from "@mui/material";
import Liner from "../../utils/Liner";


interface IProps {
  unfreezeSwt: (index: number, getValue: number | string) => void;
  setfreezeSwtInput: (value: any) => void;
  handleSwtClaimChange: (e: number | string) => void;
  freezeSwtInput: string;
  until: string;
  currentBlockState: any;
  sweetCount: string;
  index: number;
}

export default function ModalClaimAccount({
  setfreezeSwtInput,
  unfreezeSwt,
  freezeSwtInput,
  handleSwtClaimChange,
  sweetCount,
  until,
  index,
  currentBlockState,
}: IProps) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const [ID, setID] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [percent, setPercent] = useState(0);
  const [getValue, setValue] = useState(percent ? percent : freezeSwtInput);

  const getID = (id) => {
    setID(id);
  };

  const sweetPercent = (asds) => {
    setValue(asds);
    setPercent(asds !== 0 ? asds : sweetCount);
  };

  const actives = [
    {
      title: 25,
      id: 1,
      percent: (title) => {
        return (parseInt(sweetCount) * parseInt(title)) / 100;
      },
    },
    {
      title: 50,
      id: 2,
      percent: (title) => {
        return (parseInt(sweetCount) * parseInt(title)) / 100;
      },
    },
    {
      title: 75,
      id: 3,
      percent: (title) => {
        return (parseInt(sweetCount) * parseInt(title)) / 100;
      },
    },
    {
      title: 100,
      id: 4,
      percent: (title) => {
        return (parseInt(sweetCount) * parseInt(title)) / 100;
      },
    },
  ];


  return (
    <>
      <div>
        <button
          onClick={handleOpen}
          className={`${
            until < currentBlockState && Number(sweetCount) !== 0
              ? "presale-claim"
              : "presale-claim-inactive"
          } `}
          disabled={
            until < currentBlockState && Number(sweetCount) !== 0 ? false : true
          }
        >
          Claim
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEnforceFocus
        >
          <Box sx={style}>
            <Box className="modal-box">
              <Typography>SWT: {sweetCount}</Typography>
              <Box>
                <Box>
                  <Input
                    onChange={(event) => {
                      setValue(event.target.value);
                      handleSwtClaimChange(getValue);
                    }}
                    value={getValue}
                    placeholder="00,000.00"
                  />
                </Box>
                <Box className="modal-percent">
                  {actives.map((item: any, index: number) => {
                    return (
                      <Liner
                        getID={getID}
                        sweetPercent={sweetPercent}
                        active={ID === item.id}
                        title={item.title}
                        id={item.id}
                        sweetCount={sweetCount}
                      />
                    );
                  })}
                </Box>
              </Box>
              <Box>
                <Button
                  onClick={() => unfreezeSwt(index, getValue)}
                  // disabled={until < currentBlockState ? false : true}
                  // className={
                  //   until < currentBlockState
                  //     ? "active-unfreeze"
                  //     : "passive-unfreeze"
                  // }
                  className="active-unfreeze"
                >
                  Claim
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
}
function index(getValue: string | number, index: any) {
  throw new Error("Function not implemented.");
}
