import { Grid ,Box} from "@mui/material";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Logo from "../../svg/Logo";
import Footer from "../AboutFooter/footer";
export default function Apiswap() {
  const [menu, setMenu] = useState(false);


  return (
    <>
      <Box
        className="page-content rowHeader"
        style={{
          background: "linear-gradient(108.79deg,#4c248a,#7b4dcf)",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <Box
          className="api-logo"
          style={{
            margin: "0 auto",
            width: "1000px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item className="d-flex" lg={1} sm={3}>
            <Logo />
          </Grid>
          <Grid
            item
            lg={11}
            sm={3}
            className={`nav-and-connect apiswap-nav  ${
              menu ? "show-nav-panel" : ""
            }`}
          >
            <a className="info-item-h2" href="/#calculator-id">
              Calculator
            </a>
            <a className="info-item-h2" href="/#tokeno-conetnt">
              Tokenomics
            </a>
            <a className="info-item-h2" href="/#roadmap-scroll">
              RoadMap
            </a>
            <a className="info-item-h2" href="/#quarter-divider">
              Media
            </a>
          </Grid>
          <IconButton className="icon-button" onClick={() => setMenu(!menu)}>
            <Box
              className={`burger-line burger-line1 ${
                menu ? "rotate1" : "rotate-back1"
              }`}
            ></Box>
            <Box
              className={`burger-line burger-line2 ${menu ? "open" : ""}`}
            ></Box>
            <Box
              className={`burger-line burger-line3 ${
                menu ? "rotate2" : "rotate-back2"
              }`}
            ></Box>
          </IconButton>
        </Box>
      </Box>
      <Box
        style={{
          background: "#e5e0f6",
        }}
      >
        <div className="content-api">
          <div
            style={{
              padding: "30px 0px 80px 0px",
            }}
          >
            <iframe
              id="nesgt"
              className="api-frame"
              style={{ display: "block", overflow: "hidden" }}
              src="https://apeswap.finance/swap"
              width="400px"
              height="550px"
            />
          </div>
        </div>
      </Box>
      <div
        style={{
          background: "rgb(229, 224, 246)",
        }}
      >
        <Footer />
      </div>
    </>
  );
}
