import { Button, Input, Box, Typography } from "@mui/material";
import React from "react";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

interface Iprops {
  disableInput: boolean;
  sweetCoin: string | number;
  handleFocus: () => void;
  handleChangeCoin: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSweetCoin: (value: string) => void;
}
export default function SWTcalc({
  disableInput,
  sweetCoin,
  handleFocus,
  handleChangeCoin,
  setSweetCoin,
}: Iprops) {
  const { presaleSweetCoin, accountSweet } = useTypedSelector(
    (state) => state.socials
  );
  const maxSweetCin = () => {
    setSweetCoin(presaleSweetCoin + accountSweet);
  };
  return (
    <Box className="swt-calc-coin">
      <Box className={"calculator-sweetcoin"}>
        <Typography className="calculator-sweetcoin-title">SWT</Typography>
        <Input
          className={`calculator-sweetcoin-input ${
            disableInput ? "disabledInputActive" : ""
          }`}
          disableUnderline
          type="text"
          placeholder="0.00"
          value={sweetCoin}
          onFocus={handleFocus}
          onChange={handleChangeCoin}
        />
        <Button className="max-sweet" onClick={() => maxSweetCin()}>
          Max
        </Button>
      </Box>
    </Box>
  );
}
