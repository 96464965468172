import { Box, ClickAwayListener } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AboutFooter from "../../components/AboutFooter/AboutFooter";
import Footer from "../../components/AboutFooter/footer";
import Headera from "../../components/Header/misc/header";
import LivePools from "../../components/Main/LivePools/LivePools";
import Partners from "../../components/Main/partners/Partners";
import Roadmap from "../../components/Main/roadmap";
import StackingLeadboard from "../../components/Main/Stacking/Stacking-Leadboard";
import Tokeno from "../../components/Main/Tokeno";
import Tires from "../../components/tires";
import { SocialActionCreators } from "../../store/reducers/socials/action-creators";
import "./utils.scss";

const  Home:React.FC =  () =>{
  const [menu, setMenu] = useState<boolean>(false);

  const dispatch = useDispatch();

  const connectWallet = (bool: boolean) => {
    dispatch(SocialActionCreators.setIsConnectButtonClicked(bool));
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const handleClickAway = () => {
    setMenu(!menu);
  };

  return (
    <Box>
      <header>
        <Box className={"header-parent-section"}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Headera
              menu={menu}
              setMenu={(menu: boolean) => setMenu(menu)}
              connectWallet={(bool: boolean) => connectWallet(bool)}
            />
          </ClickAwayListener>
        </Box>
      </header>

      <main className="main-content">
        <Box className="first-main-content">
          <Box className="live-pools-conetnt">
            <LivePools />
          </Box>
          <div className="element">
            <Box className="calculator-conetnt">
              <Tires />
            </Box>
            <Box className="tokeno-conetnt" id="tokeno-conetnt">
              <Tokeno />
            </Box>
          </div>
        </Box>

        <Box className="second-main-content">
          <Box className="roadmap-content">
            <Roadmap />
          </Box>
          <Box className="partners-conetnt" id="quarter-divider-mobile">
            <Partners />
          </Box>

          <Box className="second-roadmap-content">
            <Box className="stacking-leadbord-content">
              <StackingLeadboard />
            </Box>
          </Box>
          <Box className="second-about-content">
            <AboutFooter />
          </Box>
        </Box>
      </main>
      <footer>
        <Footer />
      </footer>
    </Box>
  );
}
export default Home