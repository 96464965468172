import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { SocialActionCreators } from "../../store/reducers/socials/action-creators";
import ConnectButton from "./ConnectButton";
interface Iprops {
  connectWallet: (bool: boolean) => void;
}
const WalletConnect = ({ connectWallet }: Iprops) => {
  const { openBurgerMenu, drawwerMenu } = useTypedSelector(
    (state) => state.socials
  );
  const dispatch = useDispatch();
  const handleOpenBurgerMenu = () => {
    dispatch(SocialActionCreators.setOpenBurgerMenu(false));
    if (drawwerMenu) {
      dispatch(SocialActionCreators.setOpenDrawerMenu(false));
    }
  };

  return (
    <div className="wallet-connect">
      <ConnectButton
        // handleOpenModal={onOpen}
        // connectOpen={connectOpen}
        connectWallet={connectWallet}
      />
    </div>
  );
};
export default WalletConnect;
