import { Box, Typography } from "@mui/material";

import React, { useState } from "react";
import VideoSweetElem from "../../assets/VideoSweet";

import Play from "../../svg/Play.png";
import PlayMob from "../../svg/Play-mob.png";

export default function AboutFooter() {
  const [show, setShow] = useState<boolean>(false);
  return (
    <Box className="about-content">
      <div>
        <div className="youtube-about-video">
          <>
            <div className="about-video-youtube">
              <Typography className="pool-title about-us">About Us</Typography>
              <div className="videos-iframe">
                {show ? (
                  <>
                    <VideoSweetElem show={show} />
                  </>
                ) : (
                  <>
                    <span onClick={() => setShow(true)} className={"playBtn"}>
                      <img src={Play} alt="Play" />
                    </span>
                    <span
                      onClick={() => setShow(true)}
                      className={"playBtn-mobile"}
                    >
                      <img src={PlayMob} alt="Play" />
                    </span>
                  </>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </Box>
  );
}
