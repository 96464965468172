import { Box, Grid } from "@mui/material";
import React from "react";
import { ReactNode } from "react";

// import "./Layout.scss";
type Props = {
  children?: ReactNode;
  // firstLoaded: boolean;
};

export default function Layout({
  children,
}: // firstLoaded
Props) {
  return (
    <Grid
      className={` page-content  background-content`}
      // style={{ opacity: firstLoaded ? 0.1 : 1 }}
    >
      <Box className="content">{children}</Box>
    </Grid>
  );
}
