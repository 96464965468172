import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { SocialActionCreators } from "../../store/reducers/socials/action-creators";
import { Button } from "@mui/material";
interface SWTprops {
  btnName?: string;
}
export default function BuySweetButton({ btnName }: SWTprops) {
  const {  connected } = useTypedSelector((state) => state.socials);

 
  const dispatch = useDispatch();

 
  const handleOpenConnect = () => {
    dispatch(SocialActionCreators.setIsConnectButtonClicked(true));
  };
  let locale = localStorage.getItem("walletconnectedaddress");
  return (
    <>
      {locale && connected ? (
        <Button
        >
          {btnName}
        </Button>
      ) : (
        <Button
     
          onClick={handleOpenConnect}
        >
          {btnName}
        </Button>
      )}
    </>
  );
}
