import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PinkSale from "../../../svg/pink-drag.png";
import PinksaleDrag from "../../../svg/pink-monkey.png";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import X from "../../../svg/swt-pink-cl.png";
import TextSweet from "../../../svg/SweetPad-drag.png";

import ApeswapName from "../../../svg/apeswap-name.png";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  p: 5,
};

interface Iprops {
  handleClose: () => void;
}
export default function PinkSaleModal({ handleClose }: Iprops) {
  return (
    <div>
      <Box sx={style} className="pinksale-monkey">
        <Typography component={"h3"}> OFFICIAL PARTNERSHIP</Typography>

        <Box className="flex-card">
          <Box className="blok-monkey">
            <Box className="drag-monkey-blok">
              <img src={PinkSale} alt="PinksaleDrag" className="drag" />
              <img src={TextSweet} alt="TextSweet" className="text-swt" />
            </Box>

            <img src={X} alt="PinksaleDrag" />

            <Box className="apeswap-images">
              <img src={PinksaleDrag} alt="PinksaleDrag" className="monkey" />

              <img
                src={ApeswapName}
                alt="ApeswapName"
                className="apeswapName"
              />
            </Box>
          </Box>
          <a
            className="buy-sweet-btn-href"
            target={"_newblank"}
            href="https://apeswap.finance/swap"
          >
            <Button className="buy-sweet-now">Buy Now</Button>
          </a>
        </Box>
      </Box>
    </div>
  );
}
