import { Box, Button, TextField, Typography } from '@mui/material'
import { ethers } from 'ethers'
import React, { useState, useCallback, useEffect, useRef } from 'react'
import { AbiItem } from 'web3-utils'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import LpTokkenAbi from '../../../../utils/ABI/lp-tokken.json'
import SweetFreezingTest_abi from '../../../../utils/ABI/sweet-freezing.json'
import ClipLoader from 'react-spinners/ClipLoader'

import {
  lpTokkenAddress,
  sweetFreezing,
} from '../../../../utils/contractaddresses'
import DaysCalc from '../SWT/days-calc'
interface Iprops {
  isDisabledMinus: boolean
  handleDeCrease: () => void
  isDisabledPlus: boolean
  days: number
  handleInCrease: () => void
  handleChangeDays: (e: React.ChangeEvent<HTMLInputElement>) => void
  setSweetCoin: (value: string) => void
  handleBlurDays?: (e: any) => void
  setResult: (value: number) => void
  warningText: string
  result: number
  tab?: string
  sweetCoin: string
}
export default function LPstacking({
  isDisabledMinus,
  handleDeCrease,
  isDisabledPlus,
  days,
  handleInCrease,
  handleChangeDays,
  handleBlurDays,
  warningText,
  result,
  setSweetCoin,
  tab,
  setResult,
}: Iprops) {
  const { provider } = useTypedSelector((state) => state.socials)
  const [sweetLp, setSweetLp] = useState<any>(0)
  const [maxLp, setMaxLp] = useState<any>(0)
  const loading = useRef<boolean>(false)
  const [color, _] = useState<string>('#ffffff')
  const [first, setfirst] = useState(false)

  const handleSweetLpCoin = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value)
    setSweetLp(value)
    setResult(Number(value) * 5000)
  }
  const handleClickApprove = async (e) => {
    try {
      loading.current = true
      setfirst(true)
      let maxApprove: any = ethers.constants.MaxUint256
      let lpContract = await new provider.eth.Contract(
        LpTokkenAbi as AbiItem[],
        lpTokkenAddress,
      )
      let accounts = await provider.eth.getAccounts()

      let maxAmount = await lpContract.methods
        .approve(sweetFreezing, maxApprove)
        .send({
          from: accounts[0],
        })
      let max = await lpContract.methods
        .allowance(accounts[0], sweetFreezing)
        .call()
      setMaxLp(max)
    } catch {
      loading.current = false
      setfirst(false)
    }
  }

  const freezeLp = async (e) => {
    try {
      loading.current = true
      setfirst(true)
      let sweetContract = await new provider.eth.Contract(
        SweetFreezingTest_abi as AbiItem[],
        sweetFreezing,
      )
      let accounts = await provider.eth.getAccounts()
      let blocks = await sweetContract.methods.getBlocksPerDay().call()

      await sweetContract.methods
        .freezeLP(provider.utils.toWei(String(sweetLp)), blocks * days)
        .send({
          from: accounts[0],
        })
    } catch {
      loading.current = false
      setfirst(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  const getData = useCallback(async () => {
    try {
      let lpContract = await new provider.eth.Contract(
        LpTokkenAbi as AbiItem[],
        lpTokkenAddress,
      )

      let accounts = await provider.eth.getAccounts()
      let max: any = await lpContract.methods
        .allowance(accounts[0], sweetFreezing)
        .call()
      setMaxLp(max)
    } catch {}
  }, [])

  const getMaxLpBalance = async () => {
    let lpContract = await new provider.eth.Contract(
      LpTokkenAbi as AbiItem[],
      lpTokkenAddress,
    )
    let accounts = await provider.eth.getAccounts()

    let balanceLp = await lpContract.methods.balanceOf(accounts[0]).call()
    let c: any = await provider.utils.fromWei(balanceLp)

    setSweetLp(Number(c).toFixed())
  }

  return (
    <Box className="lp-card-section">
      <Typography className="swt-calc-title">
        Stake your LP and  earn xSWT.
      </Typography>
      <Box className="lp-input-section">
        <Typography className="lp-input calculator-sweetcoin-title">LP</Typography>
        <TextField
          className="sweetcoin-input lp-stacking"
          type="text"
          placeholder="0.00"
          value={sweetLp == 0 ? '' : sweetLp}
          // onFocus={handleFocus}
          onChange={handleSweetLpCoin}
        />
        <Button className="max-sweet" onClick={() => getMaxLpBalance()}>
          Max
        </Button>
      </Box>

      <DaysCalc
        isDisabledMinus={isDisabledMinus}
        handleDeCrease={handleDeCrease}
        isDisabledPlus={isDisabledPlus}
        handleInCrease={handleInCrease}
        days={days}
        handleChangeDays={handleChangeDays}
        // handleBlurDays={handleBlurDays}
        warningText={warningText}
        result={result}
      />
      <div className="lp-stack buttons-group">
        {maxLp > 0 ? (
          <>
            <Button onClick={(e) => freezeLp(e)}>Stake</Button>
            {loading.current || first ? (
              <ClipLoader color={color} size={20} />
            ) : null}
          </>
        ) : (
          <Button onClick={(e) => handleClickApprove(e)}>
            Approve
            {loading.current || first ? (
              <ClipLoader color={color} size={20} />
            ) : null}
          </Button>
        )}
      </div>
    </Box>
  )
}
