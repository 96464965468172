import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { AbiItem } from "web3-utils";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { SocialActionCreators } from "../../store/reducers/socials/action-creators";
import BnbSvg from "../../svg/bnbSvg.svg";
import Busd from "../../svg/Busd";
import CopyIcon from "../../svg/copy.png";
import NftImage from "../../svg/nftlogoSvg.svg";
import StakeImage from "../../svg/stakedSvg.svg";
import SweetLogo from "../../svg/sweetSvg.svg";
import SweetNftFreezingAbi from "../../utils/ABI/nft-freezing-test.json";
import PresaleTokken from "../../utils/ABI/presale-token.json";
import SweetFreezingTest_abi from "../../utils/ABI/sweet-freezing.json";
import {
  presaleAddress,
  sweetFreezing,
  sweetNftSFreezing,
} from "../../utils/contractaddresses";
import DayDown from "./DayDown";
import classes from "./Drawer.module.scss";
import ModalClaimAccount from "./ModalClaimAccount";
import { changeAnchor } from "./Web3Modal/helpers/drawwer-chanje";

const Drawer = (props: any) => {
  const {
    drawer,
    animate,
    hidden,
    overlay,
    overlayOpen,
    overlayHidden,
    header,
  } = classes;
  const { open, anchor, onClose } = props;
  const [copied, setCopied] = useState(false);
  const [bnb, setBnb] = useState<any>(0);
  const {
    userNfts,
    currentBlock,
    freezesBlock,
    provider,
    accountLocal,
    busdCoin,
    sweetCoin,
    freezesNftID,
    accountSweet,
    presaleSweetCoin,
    bnbBalance,
    freezesNft,
  } = useTypedSelector((state) => state.socials);

  const [freezeSwtInput, setfreezeSwtInput] = useState<string>("");
  const [allSweetCount, setAllSweetCount] = useState(0);
  const [days, setdays] = useState<any>([]);
  const [sweetCount, setSweetCount] = useState<any>([]);
  const [currentBlockState, setCurrentBlock] = useState<any>(null);
  const [nftIdFrezes, setnftIdFrezes] = useState([]);
  const dispatch = useDispatch();

  let locale: any = localStorage.getItem("walletconnectedaddress");

  useEffect(() => {
    if (localStorage.getItem("walletconnectedaddress")) {
      dispatch(SocialActionCreators.setAccountLocal(locale));
    }

    const data = async () => {
      let accounts = await provider.eth.getAccounts();

      let balance = await provider.eth.getBalance(accounts[0]);

      dispatch(
        SocialActionCreators.setBnbBalance(
          await provider.utils.fromWei(balance)
        )
      );

      let freezesUntill = freezesBlock.map((item: any) => {
        return { until: item.frozenUntil, count: item[2], period: item.period };
      });

      setSweetCount(freezesUntill);
    };
    const getCurrentBlockData = async () => {
      try {
        let currentBlock = await provider.eth.getBlockNumber();
        setCurrentBlock(currentBlock);
      } catch {}
    };

    getCurrentBlockData();
    data();
  }, [freezesBlock]);

  // const getDays = useCallback(async () => {
  //   let accounts = await provider.eth.getAccounts();

  //   let freezingSweet = await new provider.eth.Contract(
  //     SweetFreezingTest_abi as AbiItem[],
  //     sweetFreezing
  //   );
  //   let getFreezes = await freezingSweet.methods.getFreezes(accounts[0]).call();
  //   // dispatch(SocialActionCreators.setFreezesBlock(getFreezes));

  //   getFreezes?.map((item, index) => {
  //     fetch(
  //       `https://api.bscscan.com/api?module=block&action=getblockcountdown&blockno=${item["frozenUntil"]}&apikey=VAHIGIUMUWN3AWX27JXFI4AH9UJBEG9C15`
  //     )
  //       .then((res) => {
  //         if (res) {
  //           return res.json();
  //         }
  //         throw res;
  //       })
  //       .then((response) => {
  //         setdays((prevState) => {
  //           return [...prevState, response];
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   });
  // }, []);
  // useEffect(() => {
  //   getDays();
  // }, []);
  const handleSwtClaimChange = (value) => {
    setfreezeSwtInput(value);
  };
  const unfreezeSwt = async (index: number, getValue: number | string) => {
    let freezingSweet = await new provider.eth.Contract(
      SweetFreezingTest_abi as AbiItem[],
      sweetFreezing
    );

    let accounts = await provider.eth.getAccounts();
    // const unf = await freezingSweet.methods
    //   .getPower(String(item.count) - Number(freezeSwtInput), item.period)
    //   .call();

    let b = await freezingSweet.methods
      .unfreezeSWT(index, provider.utils.toWei(String(getValue)))
      .send({
        from: accounts[0],
      });
  };

  // useEffect(() => {}, [freezesNftID]);

  // useEffect(() => {
  //   const data = async () => {
  //     let sweetNftFreeze = await new provider.eth.Contract(
  //       SweetNftFreezingAbi as AbiItem[],
  //       sweetNftSFreezing
  //     );
  //     let accounts = await provider.eth.getAccounts();

  //     let freezesNftId = await sweetNftFreeze.methods
  //       .getNftsFrozeByUser(accounts[0])
  //       .call();
  //     setnftIdFrezes(freezesNftId);
  //     console.log(freezesNftId, "_____freezesNftId_____");
  //     // freezesNftId.map(async (item) => {
  //     //   return await sweetNftFreeze.methods
  //     //     .nftData(item)
  //     //     .call()
  //     //     .then((res) => {
  //     //       dispatch(
  //     //         SocialActionCreators.setFreezesNft(
  //     //           JSON.parse(JSON.stringify(res))
  //     //         )
  //     //       );
  //     //     });
  //     // });
  //   };
  //   data();
  // }, [open]);

  if (open) {
    document.body.style.setProperty("overflow", "hidden", "important");
  } else {
    document.body.style.removeProperty("overflow");
  }

  const unfreezeNft = async (Nftid) => {
    let sweetNftFreeze = await new provider.eth.Contract(
      SweetNftFreezingAbi as AbiItem[],
      sweetNftSFreezing
    );

    let accounts = await provider.eth.getAccounts();
    let c = await sweetNftFreeze.methods.unfreeze(Nftid).send({
      from: accounts[0],
    });
  };
  const claimedToCash = async () => {
    let presaleContract = new provider.eth.Contract(
      PresaleTokken as AbiItem[],
      presaleAddress
    );

    let accounts = await provider.eth.getAccounts();

    await presaleContract.methods.claim().send({
      from: accounts[0],
    });
  };
  const closeMobile = () => {
    dispatch(SocialActionCreators.setOpenDrawerMenu(true));
  };

  const onCopyText = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  useEffect(() => {
    const generateNumber = freezesBlock.map((item) => {
      return provider?.utils?.fromWei(item.power);
    });

    const xswt = generateNumber.reduce((count, item) => {
      return count + +item;
    }, allSweetCount);
    setAllSweetCount(xswt);
  }, [freezesBlock]);

  return (
    <Box className="drawer-full-content">
      <div
        onClick={() => dispatch(SocialActionCreators.setOpenDrawerMenu(true))}
        className={`index-part ${overlay} ${!open && overlayHidden} ${
          open && overlayOpen
        }`}
        // onClick={onClose}
        aria-hidden="true"
      />
      <div
        className={`drawwer-box  ${drawer} ${open && animate} ${
          !open && hidden
        } ${changeAnchor(anchor, classes)}`}
      >
        <div className={`${header} header-myAccount`}>
          <div className="account-drawwer">
            <div className="box-close">
              <h3>My Account</h3>
              <div>
                <CloseIcon onClick={closeMobile} />
              </div>
            </div>
          </div>
          {/* <CloseButton onClick={onClose} /> */}
        </div>
        <Box className={"test"}>
          <Box className="account-box">
            <h3>Account Address</h3>

            <div className="account-addres">
              <p id="text-container">
                {accountLocal &&
                  `${accountLocal.slice(0, 6)}...${accountLocal.slice(
                    accountLocal.length - 4,
                    accountLocal.length
                  )}`}
              </p>
              <CopyToClipboard
                text={accountLocal && accountLocal.replace(/["]+/g, "")}
                onCopy={onCopyText}
              >
                <button className="copy-icon-container">
                  {copied ? null : <img src={CopyIcon} alt="CopyIcon" />}
                </button>
              </CopyToClipboard>
              {copied ? <span style={{ color: "red" }}>Copied.</span> : null}
            </div>
          </Box>
          <Box>
            <Box className="coins-block">
              <div className="presale-section">
                {presaleSweetCoin <= 0 || null ? null : (
                  <>
                    <p>Presale amount</p>
                    <Typography>
                      {presaleSweetCoin && Number(presaleSweetCoin).toFixed(3)}
                    </Typography>
                    <Button
                      className="presale-claim"
                      onClick={claimedToCash}
                      disabled={presaleSweetCoin <= 0 || null ? true : false}
                    >
                      Claim
                    </Button>
                  </>
                )}
              </div>

              <Box className="coins">
                <Typography component={"h3"}>Coins</Typography>
                <Box className="balance-draw-coin">
                  <Box className="balance-draw">
                    <Box className="b-1">
                      <img src={BnbSvg} alt="BnBSvg" />
                      <Typography component={"span"}>BNB:</Typography>
                    </Box>
                    <p>{bnbBalance && Number(bnbBalance).toFixed(3)}</p>
                  </Box>
                  <Box className="balance-draw">
                    <Box className="b-2">
                      {/*<img src={BustSvg} alt="BustSvg" /> */}
                      <Busd />
                      <span>BUSD:</span>
                    </Box>
                    <p>{busdCoin && Number(busdCoin).toFixed(3)}</p>
                  </Box>
                </Box>
                <Box className="balance-draw-coin">
                  <div className="balance-draw">
                    <div className="b-3">
                      <img src={SweetLogo} alt="SweetLogo" />
                      <span>SWT:</span>
                    </div>
                    <p>{accountSweet && Number(accountSweet).toFixed(3)}</p>
                  </div>
                </Box>
              </Box>
            </Box>

            <div className="sweet-freezes-block">
              <div className="frezes-count">
                <div className="frezes-staked">
                  <span>
                    <img
                      src={StakeImage}
                      alt="StakeImage"
                      className="stake-logo"
                    />
                    <span>Staked:</span>
                  </span>
                  <span>{allSweetCount} xSWT</span>
                </div>
                <div className="staked">
                  <div className="staked-days">
                    <p>days</p>
                  </div>

                  {sweetCount.some((el) => el.count != "0") ? (
                    <>
                      {sweetCount.map((item: any, index: number) => {
                        console.log(item, "item utilssss!!!!!!!!!!!!");
                        if (item.count != "0") {
                          return (
                            <div
                              className={`staked-swt  ${
                                item.count == 0 ? "first-count" : "all"
                              } `}
                            >
                              <span> SWT:</span>
                              <span> {provider.utils.fromWei(item.count)}</span>
                              <span>
                                {item.count == 0 ? (
                                  0
                                ) : (
                                  <DayDown
                                    deadline={
                                      days[index]?.result.EstimateTimeInSec
                                    }
                                  />
                                )}
                              </span>
                              <ModalClaimAccount
                                sweetCount={provider.utils.fromWei(item.count)}
                                until={item.until}
                                currentBlockState={currentBlockState}
                                freezeSwtInput={freezeSwtInput}
                                handleSwtClaimChange={handleSwtClaimChange}
                                setfreezeSwtInput={setfreezeSwtInput}
                                unfreezeSwt={unfreezeSwt}
                                index={index}
                              />
                            </div>
                          );
                        }
                      })}
                    </>
                  ) : (
                    <div className="staked-swt">
                      <span>SWT: </span>
                      <span>0</span>
                      <span>0</span>
                      <button className={`presale-claim-inactive`}>
                        Claim
                      </button>
                    </div>
                  )}
                  <div className="staked-swt">
                    <span>LP:</span>
                    <span className="staked-lp">0</span>
                    <span>0</span>
                    <div>
                      <button className="presale-claim-inactive">Claim</button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {/* <ModalClaimAccount
                  // until={item.until}
                  currentBlockState={currentBlockState}
                  freezeSwtInput={freezeSwtInput}
                  handleSwtClaimChange={handleSwtClaimChange}
                  // unfreezeSwt={() => unfreezeSwt(index)}
                /> */}

                {/* {sweetCount?.filter((item: any, index) => {})} */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "15px",
                }}
              ></div>
            </div>

            <div className="nft-freezes-block">
              <div className="my-nft">
                {/*<img src={StakeNFT} alt="StakeNFT" />*/}
                <div className="nft-first">
                  <div className="nft-title">
                    <img src={NftImage} alt="nft-image" />
                    <span> NFT: </span>
                  </div>
                  <button className="buy-nft_button">Buy NFT</button>
                </div>
                <div className="nft-list">
                  <div className="nft-choco nft-items">
                    <div className="choco-title">
                      <span>Choco:</span>
                      <span>Tickets</span>
                      <span>Days</span>
                    </div>
                    <div className="choco-features">
                      <button className="buy-button">Buy</button>
                      <span>0</span>
                      <span>0</span>
                      <button className="presale-claim-inactive">Claim</button>
                    </div>
                  </div>
                  <div className="nft-frodo nft-items">
                    <div className="choco-title">
                      <span>Frodo:</span>
                    </div>
                    <div className="choco-features">
                      <button className="buy-button">Buy</button>
                      <span>0</span>
                      <span>0</span>
                      <button className="presale-claim-inactive">Claim</button>
                    </div>

                    {/*<div className="staked-nfts">*/}

                    {/*    <div className="staked-nfts-items">*/}
                    {/*        <span>#34</span>*/}
                    {/*        <span>12</span>*/}
                    {/*        <span>564</span>*/}
                    {/*        <button className="presale-claim-inactive">Claim</button>*/}
                    {/*    </div>*/}
                    {/*    <div className="staked-nfts-items">*/}
                    {/*        <span>#35</span>*/}
                    {/*        <span>12</span>*/}
                    {/*        <span>564</span>*/}
                    {/*        <button className="presale-claim-inactive">Claim</button>*/}
                    {/*    </div>*/}
                    {/*    <div className="staked-nfts-items">*/}
                    {/*        <span>#36</span>*/}
                    {/*        <span>12</span>*/}
                    {/*        <span>564</span>*/}
                    {/*        <button className="presale-claim-inactive">Claim</button>*/}
                    {/*    </div>*/}
                    {/*    <div className="staked-nfts-items">*/}
                    {/*        <span>#37</span>*/}
                    {/*        <span>12</span>*/}
                    {/*        <span>564</span>*/}
                    {/*        <button className="presale-claim-inactive">Claim</button>*/}
                    {/*    </div>*/}
                    {/*    <div className="staked-nfts-items">*/}
                    {/*        <span>#38</span>*/}
                    {/*        <span>12</span>*/}
                    {/*        <span>564</span>*/}
                    {/*        <button className="presale-claim-inactive">Claim</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                  </div>
                  <div className="nft-hugo nft-items">
                    <div className="choco-title">
                      <span>Hugo:</span>
                    </div>
                    <div className="choco-features">
                      <button className="buy-button">Buy</button>
                      <span>0</span>
                      <span>0</span>
                      <button className="presale-claim-inactive">Claim</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="block-section-nft-and-swt">
                <div className="nft-info-section">
                  {freezesNft ? (
                    <>
                      {[...freezesNft]?.map((item: any) => {
                        let image = item.image.replace(
                          "ipfs://",
                          "https://ipfs.io/ipfs/"
                        );
                        return (
                          <div key={item.name}>
                            <h2>{item.name}</h2>
                            <img src={`${image}`} />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <span>You have not Nfts</span>
                      <div className="spinner-nft">
                        <ClipLoader color={"#000000"} size={30} />
                      </div>
                    </>
                  )}
                </div>
                <div className="unfreeze-nft-section">
                  {freezesNft.map((item: any, index: number) => {
                    if (freezesNftID) {
                      item.id = freezesNftID[index];
                    }
                    return (
                      <div>
                        <Button
                          className="claim-nft"
                          disabled={
                            item.freezeEndBlock < currentBlockState
                              ? false
                              : true
                          }
                          onClick={() => unfreezeNft(item.id)}
                        >
                          claim
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Box>

          <Box className="disconnect-button-section">
            <Button
              //   variant="outline"
              className="disconnect-account"
              //   mr={3}
              onClick={props.killSessionCall}
            >
              Disconnect
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default React.memo(Drawer);
